/**
 * Generated by @openapi-codegen
 *
 * @version 1.0.0
 */
import * as reactQuery from "@tanstack/react-query";
import { useWalletosContext, WalletosContext } from "./walletosContext";
import type * as Fetcher from "./walletosFetcher";
import { walletosFetch } from "./walletosFetcher";

export type ResetPasswordError = Fetcher.ErrorWrapper<undefined>;

export type ResetPasswordVariables = {
  body?: Record<string, any>;
} & WalletosContext["fetcherOptions"];

/**
 * This API endpoint allows users to change password
 */
export const fetchResetPassword = (
  variables: ResetPasswordVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    ResetPasswordError,
    Record<string, any>,
    {},
    {},
    {}
  >({
    url: "/v1/authentication/reset-password",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint allows users to change password
 */
export const useResetPassword = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      ResetPasswordError,
      ResetPasswordVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    ResetPasswordError,
    ResetPasswordVariables
  >({
    mutationFn: (variables: ResetPasswordVariables) =>
      fetchResetPassword({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ConfirmPasswordResetError = Fetcher.ErrorWrapper<undefined>;

export type ConfirmPasswordResetVariables = {
  body?: Record<string, any>;
} & WalletosContext["fetcherOptions"];

/**
 * This API endpoint allows users to change password
 */
export const fetchConfirmPasswordReset = (
  variables: ConfirmPasswordResetVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    ConfirmPasswordResetError,
    Record<string, any>,
    {},
    {},
    {}
  >({
    url: "/v1/authentication/confirm-reset-password",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint allows users to change password
 */
export const useConfirmPasswordReset = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      ConfirmPasswordResetError,
      ConfirmPasswordResetVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    ConfirmPasswordResetError,
    ConfirmPasswordResetVariables
  >({
    mutationFn: (variables: ConfirmPasswordResetVariables) =>
      fetchConfirmPasswordReset({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DisableMfaError = Fetcher.ErrorWrapper<undefined>;

export type DisableMfaVariables = {
  body?: Record<string, any>;
} & WalletosContext["fetcherOptions"];

/**
 * This API endpoint allows users to disable their 2FA.
 */
export const fetchDisableMfa = (
  variables: DisableMfaVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    DisableMfaError,
    Record<string, any>,
    {},
    {},
    {}
  >({
    url: "/v1/business/disable-multi-factor-auth",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint allows users to disable their 2FA.
 */
export const useDisableMfa = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      DisableMfaError,
      DisableMfaVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    DisableMfaError,
    DisableMfaVariables
  >({
    mutationFn: (variables: DisableMfaVariables) =>
      fetchDisableMfa({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type VerifyMfaError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Record<string, any>;
}>;

export type VerifyMfaVariables = {
  body?: Record<string, any>;
} & WalletosContext["fetcherOptions"];

/**
 * This API endpoint allows users to verify their TOTP if 2FA is enabled for their account.
 */
export const fetchVerifyMfa = (
  variables: VerifyMfaVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    VerifyMfaError,
    Record<string, any>,
    {},
    {},
    {}
  >({
    url: "/v1/business/verify-multi-factor-auth",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint allows users to verify their TOTP if 2FA is enabled for their account.
 */
export const useVerifyMfa = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      VerifyMfaError,
      VerifyMfaVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    VerifyMfaError,
    VerifyMfaVariables
  >({
    mutationFn: (variables: VerifyMfaVariables) =>
      fetchVerifyMfa({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EnableMfaError = Fetcher.ErrorWrapper<undefined>;

export type EnableMfaVariables = WalletosContext["fetcherOptions"];

export const fetchEnableMfa = (
  variables: EnableMfaVariables,
  signal?: AbortSignal
) =>
  walletosFetch<undefined, EnableMfaError, undefined, {}, {}, {}>({
    url: "/v1/business/enable-multi-factor-auth",
    method: "post",
    ...variables,
    signal,
  });

export const useEnableMfa = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      EnableMfaError,
      EnableMfaVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<undefined, EnableMfaError, EnableMfaVariables>({
    mutationFn: (variables: EnableMfaVariables) =>
      fetchEnableMfa({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type LoginError = Fetcher.ErrorWrapper<undefined>;

export type LoginVariables = {
  body?: Record<string, any>;
} & WalletosContext["fetcherOptions"];

/**
 * This API endpoint allows users to authenticate and obtain an access token for subsequent requests.
 */
export const fetchLogin = (variables: LoginVariables, signal?: AbortSignal) =>
  walletosFetch<
    Record<string, any>,
    LoginError,
    Record<string, any>,
    {},
    {},
    {}
  >({ url: "/v1/authentication/login", method: "post", ...variables, signal });

/**
 * This API endpoint allows users to authenticate and obtain an access token for subsequent requests.
 */
export const useLogin = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      LoginError,
      LoginVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    LoginError,
    LoginVariables
  >({
    mutationFn: (variables: LoginVariables) =>
      fetchLogin({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type LogoutError = Fetcher.ErrorWrapper<undefined>;

export type LogoutVariables = WalletosContext["fetcherOptions"];

/**
 * This API endpoint allows users to log out, terminating the current session.
 */
export const fetchLogout = (variables: LogoutVariables, signal?: AbortSignal) =>
  walletosFetch<Record<string, any>, LogoutError, undefined, {}, {}, {}>({
    url: "/v1/authentication/logout",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint allows users to log out, terminating the current session.
 */
export const useLogout = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      LogoutError,
      LogoutVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    LogoutError,
    LogoutVariables
  >({
    mutationFn: (variables: LogoutVariables) =>
      fetchLogout({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangePasswordError = Fetcher.ErrorWrapper<undefined>;

export type ChangePasswordVariables = {
  body?: Record<string, any>;
} & WalletosContext["fetcherOptions"];

/**
 * This API endpoint allows users to change password
 */
export const fetchChangePassword = (
  variables: ChangePasswordVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    ChangePasswordError,
    Record<string, any>,
    {},
    {},
    {}
  >({
    url: "/v1/authentication/change-password",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint allows users to change password
 */
export const useChangePassword = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      ChangePasswordError,
      ChangePasswordVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    ChangePasswordError,
    ChangePasswordVariables
  >({
    mutationFn: (variables: ChangePasswordVariables) =>
      fetchChangePassword({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserAccountMetadataPathParams = {
  businessId: string;
  accountId: string;
};

export type GetUserAccountMetadataError = Fetcher.ErrorWrapper<undefined>;

export type GetUserAccountMetadataVariables = {
  pathParams: GetUserAccountMetadataPathParams;
} & WalletosContext["fetcherOptions"];

/**
 * Get Invite session Information
 */
export const fetchGetUserAccountMetadata = (
  variables: GetUserAccountMetadataVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    GetUserAccountMetadataError,
    undefined,
    {},
    {},
    GetUserAccountMetadataPathParams
  >({
    url: "/v1/business/{businessId}/account/{accountId}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Get Invite session Information
 */
export const useGetUserAccountMetadata = <TData = Record<string, any>>(
  variables: GetUserAccountMetadataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetUserAccountMetadataError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useWalletosContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetUserAccountMetadataError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/account/{accountId}",
      operationId: "getUserAccountMetadata",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserAccountMetadata({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetAccountMetadataError = Fetcher.ErrorWrapper<undefined>;

export type GetAccountMetadataVariables = WalletosContext["fetcherOptions"];

/**
 * Get Invite session Information
 */
export const fetchGetAccountMetadata = (
  variables: GetAccountMetadataVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    GetAccountMetadataError,
    undefined,
    {},
    {},
    {}
  >({ url: "/v1/account", method: "get", ...variables, signal });

/**
 * Get Invite session Information
 */
export const useGetAccountMetadata = <TData = Record<string, any>>(
  variables: GetAccountMetadataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetAccountMetadataError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useWalletosContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetAccountMetadataError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/account",
      operationId: "getAccountMetadata",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAccountMetadata({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateAnAccountError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Record<string, any>;
}>;

export type CreateAnAccountVariables = {
  body?: Record<string, any>;
} & WalletosContext["fetcherOptions"];

/**
 * This endpoint allows a super admin to sign up a new account by providing their first name, last name, and email.
 */
export const fetchCreateAnAccount = (
  variables: CreateAnAccountVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    CreateAnAccountError,
    Record<string, any>,
    {},
    {},
    {}
  >({ url: "/v1/account/create", method: "post", ...variables, signal });

/**
 * This endpoint allows a super admin to sign up a new account by providing their first name, last name, and email.
 */
export const useCreateAnAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      CreateAnAccountError,
      CreateAnAccountVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    CreateAnAccountError,
    CreateAnAccountVariables
  >({
    mutationFn: (variables: CreateAnAccountVariables) =>
      fetchCreateAnAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeTemporaryPasswordError = Fetcher.ErrorWrapper<undefined>;

export type ChangeTemporaryPasswordVariables = {
  body?: Record<string, any>;
} & WalletosContext["fetcherOptions"];

/**
 * This API endpoint allows users to change their temporary password by providing their email, temporary password (sent to user’s email upon signup), and the new password.
 *
 * There are two underlying actions that take place in this stage:
 *
 * 1. The user enters the temporary password and continues to the next screen
 *
 * 2. The user then enters the new password in the field below the user’s auto generated email field
 */
export const fetchChangeTemporaryPassword = (
  variables: ChangeTemporaryPasswordVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    ChangeTemporaryPasswordError,
    Record<string, any>,
    {},
    {},
    {}
  >({
    url: "/v1/account/update-temporary-password",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint allows users to change their temporary password by providing their email, temporary password (sent to user’s email upon signup), and the new password.
 *
 * There are two underlying actions that take place in this stage:
 *
 * 1. The user enters the temporary password and continues to the next screen
 *
 * 2. The user then enters the new password in the field below the user’s auto generated email field
 */
export const useChangeTemporaryPassword = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      ChangeTemporaryPasswordError,
      ChangeTemporaryPasswordVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    ChangeTemporaryPasswordError,
    ChangeTemporaryPasswordVariables
  >({
    mutationFn: (variables: ChangeTemporaryPasswordVariables) =>
      fetchChangeTemporaryPassword({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateAnAccountPathParams = {
  accountId: string;
};

export type UpdateAnAccountError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAnAccountVariables = {
  body?: Record<string, any>;
  pathParams: UpdateAnAccountPathParams;
} & WalletosContext["fetcherOptions"];

export const fetchUpdateAnAccount = (
  variables: UpdateAnAccountVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    UpdateAnAccountError,
    Record<string, any>,
    {},
    {},
    UpdateAnAccountPathParams
  >({
    url: "/v1/admin/account/{accountId}",
    method: "put",
    ...variables,
    signal,
  });

export const useUpdateAnAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      UpdateAnAccountError,
      UpdateAnAccountVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    UpdateAnAccountError,
    UpdateAnAccountVariables
  >({
    mutationFn: (variables: UpdateAnAccountVariables) =>
      fetchUpdateAnAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetDashboardMetricsPathParams = {
  businessId: string;
};

export type GetDashboardMetricsError = Fetcher.ErrorWrapper<undefined>;

export type GetDashboardMetricsVariables = {
  pathParams: GetDashboardMetricsPathParams;
} & WalletosContext["fetcherOptions"];

export const fetchGetDashboardMetrics = (
  variables: GetDashboardMetricsVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    GetDashboardMetricsError,
    undefined,
    {},
    {},
    GetDashboardMetricsPathParams
  >({
    url: "/v1/business/{businessId}/overview",
    method: "get",
    ...variables,
    signal,
  });

export const useGetDashboardMetrics = <TData = Record<string, any>>(
  variables: GetDashboardMetricsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetDashboardMetricsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useWalletosContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetDashboardMetricsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/overview",
      operationId: "getDashboardMetrics",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDashboardMetrics({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetAssociatedUserAccountsBalancesPathParams = {
  businessId: string;
};

export type GetAssociatedUserAccountsBalancesQueryParams = {
  status?: string;
  limit?: number;
  page?: number;
};

export type GetAssociatedUserAccountsBalancesError =
  Fetcher.ErrorWrapper<undefined>;

export type GetAssociatedUserAccountsBalancesVariables = {
  pathParams: GetAssociatedUserAccountsBalancesPathParams;
  queryParams?: GetAssociatedUserAccountsBalancesQueryParams;
} & WalletosContext["fetcherOptions"];

export const fetchGetAssociatedUserAccountsBalances = (
  variables: GetAssociatedUserAccountsBalancesVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    GetAssociatedUserAccountsBalancesError,
    undefined,
    {},
    GetAssociatedUserAccountsBalancesQueryParams,
    GetAssociatedUserAccountsBalancesPathParams
  >({
    url: "/v1/business/{businessId}/users",
    method: "get",
    ...variables,
    signal,
  });

export const useGetAssociatedUserAccountsBalances = <
  TData = Record<string, any>
>(
  variables: GetAssociatedUserAccountsBalancesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetAssociatedUserAccountsBalancesError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useWalletosContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetAssociatedUserAccountsBalancesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}/users",
      operationId: "getAssociatedUserAccountsBalances",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAssociatedUserAccountsBalances(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetBusinessMetadataPathParams = {
  businessId: string;
};

export type GetBusinessMetadataError = Fetcher.ErrorWrapper<undefined>;

export type GetBusinessMetadataVariables = {
  pathParams: GetBusinessMetadataPathParams;
} & WalletosContext["fetcherOptions"];

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const fetchGetBusinessMetadata = (
  variables: GetBusinessMetadataVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    GetBusinessMetadataError,
    undefined,
    {},
    {},
    GetBusinessMetadataPathParams
  >({ url: "/v1/business/{businessId}", method: "get", ...variables, signal });

/**
 * This API endpoint provides information about the business, and gets essential details for display in a dashboard.
 */
export const useGetBusinessMetadata = <TData = Record<string, any>>(
  variables: GetBusinessMetadataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetBusinessMetadataError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useWalletosContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetBusinessMetadataError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/{businessId}",
      operationId: "getBusinessMetadata",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetBusinessMetadata({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetInviteSessionMetadataPathParams = {
  session: string;
};

export type GetInviteSessionMetadataError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Record<string, any>;
}>;

export type GetInviteSessionMetadataVariables = {
  pathParams: GetInviteSessionMetadataPathParams;
} & WalletosContext["fetcherOptions"];

export const fetchGetInviteSessionMetadata = (
  variables: GetInviteSessionMetadataVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    GetInviteSessionMetadataError,
    undefined,
    {},
    {},
    GetInviteSessionMetadataPathParams
  >({
    url: "/v1/business/invite/{session}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetInviteSessionMetadata = <TData = Record<string, any>>(
  variables: GetInviteSessionMetadataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetInviteSessionMetadataError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useWalletosContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetInviteSessionMetadataError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/business/invite/{session}",
      operationId: "getInviteSessionMetadata",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetInviteSessionMetadata(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type AcceptInviteError = Fetcher.ErrorWrapper<undefined>;

export type AcceptInviteVariables = {
  body?: Record<string, any>;
} & WalletosContext["fetcherOptions"];

/**
 * This endpoint allows a user to accept an invitation to join a business.
 * Upon successful acceptance, it retrieves information about the authenticated user.
 */
export const fetchAcceptInvite = (
  variables: AcceptInviteVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    AcceptInviteError,
    Record<string, any>,
    {},
    {},
    {}
  >({
    url: "/v1/business/invitation-join",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint allows a user to accept an invitation to join a business.
 * Upon successful acceptance, it retrieves information about the authenticated user.
 */
export const useAcceptInvite = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      AcceptInviteError,
      AcceptInviteVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    AcceptInviteError,
    AcceptInviteVariables
  >({
    mutationFn: (variables: AcceptInviteVariables) =>
      fetchAcceptInvite({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type InviteMemberPathParams = {
  businessId: string;
};

export type InviteMemberError = Fetcher.ErrorWrapper<undefined>;

export type InviteMemberVariables = {
  body?: Record<string, any>;
  pathParams: InviteMemberPathParams;
} & WalletosContext["fetcherOptions"];

/**
 * This endpoint enables a logged-in Super Admin to extend invitations to members for a business. The API accepts the invitee’s email address and role as parameters. Following a successful invitation, the user receives an email invitation, which includes a URL directing them to the signup page and the business session identifier, uniquely identifying the business platform to which the user has been invited.
 */
export const fetchInviteMember = (
  variables: InviteMemberVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    InviteMemberError,
    Record<string, any>,
    {},
    {},
    InviteMemberPathParams
  >({
    url: "/v1/business/{businessId}/invite",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This endpoint enables a logged-in Super Admin to extend invitations to members for a business. The API accepts the invitee’s email address and role as parameters. Following a successful invitation, the user receives an email invitation, which includes a URL directing them to the signup page and the business session identifier, uniquely identifying the business platform to which the user has been invited.
 */
export const useInviteMember = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      InviteMemberError,
      InviteMemberVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    InviteMemberError,
    InviteMemberVariables
  >({
    mutationFn: (variables: InviteMemberVariables) =>
      fetchInviteMember({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateBusinessAndInviteASelfOwnerError =
  Fetcher.ErrorWrapper<undefined>;

export type CreateBusinessAndInviteASelfOwnerVariables = {
  body?: Record<string, any>;
} & WalletosContext["fetcherOptions"];

/**
 * This endpoint allows an admin to create a business and invite the owner to join the platform. The authorization headers take in an AWS access key and a secret key.
 */
export const fetchCreateBusinessAndInviteASelfOwner = (
  variables: CreateBusinessAndInviteASelfOwnerVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    CreateBusinessAndInviteASelfOwnerError,
    Record<string, any>,
    {},
    {},
    {}
  >({ url: "/v1/business/create", method: "post", ...variables, signal });

/**
 * This endpoint allows an admin to create a business and invite the owner to join the platform. The authorization headers take in an AWS access key and a secret key.
 */
export const useCreateBusinessAndInviteASelfOwner = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      CreateBusinessAndInviteASelfOwnerError,
      CreateBusinessAndInviteASelfOwnerVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    CreateBusinessAndInviteASelfOwnerError,
    CreateBusinessAndInviteASelfOwnerVariables
  >({
    mutationFn: (variables: CreateBusinessAndInviteASelfOwnerVariables) =>
      fetchCreateBusinessAndInviteASelfOwner({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type CreateApiClientAndSecretPathParams = {
  businessId: string;
};

export type CreateApiClientAndSecretError = Fetcher.ErrorWrapper<undefined>;

export type CreateApiClientAndSecretVariables = {
  body?: Record<string, any>;
  pathParams: CreateApiClientAndSecretPathParams;
} & WalletosContext["fetcherOptions"];

/**
 * This API endpoint allows users with proper authorization (Bearer Token) to create an API key for their business. This endpoint requires a valid Bearer Token to authenticate the user's authorization.
 *
 * The API key serves as a secure means to access and interact with business-related functionalities and data. By generating an API key through this endpoint, you can facilitate secure communication between your application and the specified business, ensuring controlled access to the associated resources.
 */
export const fetchCreateApiClientAndSecret = (
  variables: CreateApiClientAndSecretVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    CreateApiClientAndSecretError,
    Record<string, any>,
    {},
    {},
    CreateApiClientAndSecretPathParams
  >({
    url: "/v1/business/{businessId}/api-key",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint allows users with proper authorization (Bearer Token) to create an API key for their business. This endpoint requires a valid Bearer Token to authenticate the user's authorization.
 *
 * The API key serves as a secure means to access and interact with business-related functionalities and data. By generating an API key through this endpoint, you can facilitate secure communication between your application and the specified business, ensuring controlled access to the associated resources.
 */
export const useCreateApiClientAndSecret = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      CreateApiClientAndSecretError,
      CreateApiClientAndSecretVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    CreateApiClientAndSecretError,
    CreateApiClientAndSecretVariables
  >({
    mutationFn: (variables: CreateApiClientAndSecretVariables) =>
      fetchCreateApiClientAndSecret({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateWalletConfigurationPathParams = {
  businessId: string;
};

export type CreateWalletConfigurationError = Fetcher.ErrorWrapper<{
  status: 429;
  payload: Record<string, any>;
}>;

export type CreateWalletConfigurationVariables = {
  body?: Record<string, any>;
  pathParams: CreateWalletConfigurationPathParams;
} & WalletosContext["fetcherOptions"];

/**
 * Gets information about the authenticated user.
 */
export const fetchCreateWalletConfiguration = (
  variables: CreateWalletConfigurationVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    CreateWalletConfigurationError,
    Record<string, any>,
    {},
    {},
    CreateWalletConfigurationPathParams
  >({
    url: "/v1/business/{businessId}/config/wallets",
    method: "post",
    ...variables,
    signal,
  });

/**
 * Gets information about the authenticated user.
 */
export const useCreateWalletConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      CreateWalletConfigurationError,
      CreateWalletConfigurationVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    CreateWalletConfigurationError,
    CreateWalletConfigurationVariables
  >({
    mutationFn: (variables: CreateWalletConfigurationVariables) =>
      fetchCreateWalletConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateRevenueConfigurationPathParams = {
  businessId: string;
};

export type CreateRevenueConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type CreateRevenueConfigurationVariables = {
  body?: Record<string, any>;
  pathParams: CreateRevenueConfigurationPathParams;
} & WalletosContext["fetcherOptions"];

/**
 * This API endpoint allows users with proper authorization (Bearer Token) to create an API key for their business. This endpoint requires a valid Bearer Token to authenticate the user's authorization.
 *
 * The API key serves as a secure means to access and interact with business-related functionalities and data. By generating an API key through this endpoint, you can facilitate secure communication between your application and the specified business, ensuring controlled access to the associated resources.
 */
export const fetchCreateRevenueConfiguration = (
  variables: CreateRevenueConfigurationVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    CreateRevenueConfigurationError,
    Record<string, any>,
    {},
    {},
    CreateRevenueConfigurationPathParams
  >({
    url: "/v1/business/{businessId}/config/revenue-stream",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint allows users with proper authorization (Bearer Token) to create an API key for their business. This endpoint requires a valid Bearer Token to authenticate the user's authorization.
 *
 * The API key serves as a secure means to access and interact with business-related functionalities and data. By generating an API key through this endpoint, you can facilitate secure communication between your application and the specified business, ensuring controlled access to the associated resources.
 */
export const useCreateRevenueConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      CreateRevenueConfigurationError,
      CreateRevenueConfigurationVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    CreateRevenueConfigurationError,
    CreateRevenueConfigurationVariables
  >({
    mutationFn: (variables: CreateRevenueConfigurationVariables) =>
      fetchCreateRevenueConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RotateCredentialsSecretPathParams = {
  businessId: string;
};

export type RotateCredentialsSecretError = Fetcher.ErrorWrapper<undefined>;

export type RotateCredentialsSecretVariables = {
  body?: Record<string, any>;
  pathParams: RotateCredentialsSecretPathParams;
} & WalletosContext["fetcherOptions"];

/**
 * This API endpoint allows users with proper authorization (Bearer Token) to create an API key for their business. This endpoint requires a valid Bearer Token to authenticate the user's authorization.
 *
 * The API key serves as a secure means to access and interact with business-related functionalities and data. By generating an API key through this endpoint, you can facilitate secure communication between your application and the specified business, ensuring controlled access to the associated resources.
 */
export const fetchRotateCredentialsSecret = (
  variables: RotateCredentialsSecretVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    RotateCredentialsSecretError,
    Record<string, any>,
    {},
    {},
    RotateCredentialsSecretPathParams
  >({
    url: "/v1/business/{businessId}/credentials/rotate-secret",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint allows users with proper authorization (Bearer Token) to create an API key for their business. This endpoint requires a valid Bearer Token to authenticate the user's authorization.
 *
 * The API key serves as a secure means to access and interact with business-related functionalities and data. By generating an API key through this endpoint, you can facilitate secure communication between your application and the specified business, ensuring controlled access to the associated resources.
 */
export const useRotateCredentialsSecret = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      RotateCredentialsSecretError,
      RotateCredentialsSecretVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    RotateCredentialsSecretError,
    RotateCredentialsSecretVariables
  >({
    mutationFn: (variables: RotateCredentialsSecretVariables) =>
      fetchRotateCredentialsSecret({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ConfigureWebhookPathParams = {
  businessId: string;
};

export type ConfigureWebhookError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Record<string, any>;
}>;

export type ConfigureWebhookVariables = {
  body?: Record<string, any>;
  pathParams: ConfigureWebhookPathParams;
} & WalletosContext["fetcherOptions"];

/**
 * This API endpoint allows users with proper authorization (Bearer Token) to create an API key for their business. This endpoint requires a valid Bearer Token to authenticate the user's authorization.
 *
 * The API key serves as a secure means to access and interact with business-related functionalities and data. By generating an API key through this endpoint, you can facilitate secure communication between your application and the specified business, ensuring controlled access to the associated resources.
 */
export const fetchConfigureWebhook = (
  variables: ConfigureWebhookVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    ConfigureWebhookError,
    Record<string, any>,
    {},
    {},
    ConfigureWebhookPathParams
  >({
    url: "/v1/business/{businessId}/configure-webhook",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API endpoint allows users with proper authorization (Bearer Token) to create an API key for their business. This endpoint requires a valid Bearer Token to authenticate the user's authorization.
 *
 * The API key serves as a secure means to access and interact with business-related functionalities and data. By generating an API key through this endpoint, you can facilitate secure communication between your application and the specified business, ensuring controlled access to the associated resources.
 */
export const useConfigureWebhook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      ConfigureWebhookError,
      ConfigureWebhookVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    ConfigureWebhookError,
    ConfigureWebhookVariables
  >({
    mutationFn: (variables: ConfigureWebhookVariables) =>
      fetchConfigureWebhook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EnforceActionTypePathParams = {
  businessId: string;
  accountId: string;
};

export type EnforceActionTypeQueryParams = {
  actionType?: string;
  action?: string;
};

export type EnforceActionTypeError = Fetcher.ErrorWrapper<undefined>;

export type EnforceActionTypeVariables = {
  pathParams: EnforceActionTypePathParams;
  queryParams?: EnforceActionTypeQueryParams;
} & WalletosContext["fetcherOptions"];

export const fetchEnforceActionType = (
  variables: EnforceActionTypeVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    EnforceActionTypeError,
    undefined,
    {},
    EnforceActionTypeQueryParams,
    EnforceActionTypePathParams
  >({
    url: "/v1/business/{businessId}/account/{accountId}/action",
    method: "put",
    ...variables,
    signal,
  });

export const useEnforceActionType = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      EnforceActionTypeError,
      EnforceActionTypeVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useWalletosContext();
  return reactQuery.useMutation<
    Record<string, any>,
    EnforceActionTypeError,
    EnforceActionTypeVariables
  >({
    mutationFn: (variables: EnforceActionTypeVariables) =>
      fetchEnforceActionType({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPlatformMetadataError = Fetcher.ErrorWrapper<undefined>;

export type GetPlatformMetadataVariables = WalletosContext["fetcherOptions"];

export const fetchGetPlatformMetadata = (
  variables: GetPlatformMetadataVariables,
  signal?: AbortSignal
) =>
  walletosFetch<
    Record<string, any>,
    GetPlatformMetadataError,
    undefined,
    {},
    {},
    {}
  >({ url: "/v1/metadata", method: "get", ...variables, signal });

export const useGetPlatformMetadata = <TData = Record<string, any>>(
  variables: GetPlatformMetadataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetPlatformMetadataError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useWalletosContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetPlatformMetadataError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v1/metadata",
      operationId: "getPlatformMetadata",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPlatformMetadata({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: "/v1/business/{businessId}/account/{accountId}";
      operationId: "getUserAccountMetadata";
      variables: GetUserAccountMetadataVariables;
    }
  | {
      path: "/v1/account";
      operationId: "getAccountMetadata";
      variables: GetAccountMetadataVariables;
    }
  | {
      path: "/v1/business/{businessId}/overview";
      operationId: "getDashboardMetrics";
      variables: GetDashboardMetricsVariables;
    }
  | {
      path: "/v1/business/{businessId}/users";
      operationId: "getAssociatedUserAccountsBalances";
      variables: GetAssociatedUserAccountsBalancesVariables;
    }
  | {
      path: "/v1/business/{businessId}";
      operationId: "getBusinessMetadata";
      variables: GetBusinessMetadataVariables;
    }
  | {
      path: "/v1/business/invite/{session}";
      operationId: "getInviteSessionMetadata";
      variables: GetInviteSessionMetadataVariables;
    }
  | {
      path: "/v1/metadata";
      operationId: "getPlatformMetadata";
      variables: GetPlatformMetadataVariables;
    };
