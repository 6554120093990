import {
  createFileRoute,
  Link,
  useNavigate,
  useRouter,
} from "@tanstack/react-router";
import {
  CardBackButton,
  CardBody,
  CardHeader,
  CardRoot,
  CardSeparator,
  CardTitle,
} from "~/components/layouts/Card";
import { Button } from "~/@/components/ui/button";
import {
  EmptyStateConceal,
  EmptyStateContent,
  EmptyStateRoot,
} from "~/@/components/custom/empty-state";
import { useModalHandle } from "~/hooks/use-modal";
import { Skeleton } from "~/@/components/ui/skeleton";
import { EmptyStateDescription } from "~/components/molecules/empty-state";
import { Badge } from "~/components/atoms/Badge";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogRemote,
  DialogRemoteTrigger,
  DialogTitle,
} from "~/@/components/ui/dialog";
import {
  useCreateApplicationTokenAccount,
  useGetLinkedAccounts,
} from "~/api/codegen/liquidityComponents";
import { useGetBusinessId } from "~/hooks/use-business";
import { SkeletonContent } from "~/components/atoms/skeleton";
import { empty_state_no_cards } from "~/assets";
import { WalletHolderImpl, useWallets } from "~/hooks/use-wallet";
import { Wallet, AppToken } from "~/libs/factories/wallet-factory";
import { formatNumber, getFiatCurrency } from "~/libs/currency.helpers";
import { ClickableCard } from "~/components/ClickableCard";
import { M } from "ts-toolbelt";
import { truncateString } from "~/libs/string.helper";
import { notify } from "~/components/Toast";
import { CopyIcon } from "~/assets/icons";

export const Route = createFileRoute(
  "/dashboard/wallet/collections/addAccount",
)({
  component: () => <Page />,
});

function Page() {
  return (
    <section className="flex justify-center py-12">
      <AddAccount />
    </section>
  );
}

function AddAccount() {
  const router = useRouter();
  return (
    <>
      <CardRoot size="lg" className="px-2">
        <CardHeader className="flex justify-between gap-2 ">
          <Link className="flex items-center">
            <CardBackButton
              onClick={() => {
                router.history.back();
              }}
            />
          </Link>
          <CardTitle className="text-md">Request Link</CardTitle>
        </CardHeader>
        <CardSeparator className="-mx-2" />
        <ChooseWallet />
      </CardRoot>
    </>
  );
}

function ChooseWallet() {
  const { data, isLoading } = useWallets();
  const [selected, setSelected] = useState<Wallet>();
  const [tokenDetails, setTokenDetails] = useState<AppToken>();
  const businessId = useGetBusinessId();
  const mutation = useCreateApplicationTokenAccount();

  const wallets = Array.from(
    WalletHolderImpl.toList(data, (wallet) => wallet.kind === "CORPORATE"),
  );

  const currency = getFiatCurrency(selected?.currency);

  const createApplicationToken = async () => {
    try {
      await mutation
        .mutateAsync({
          pathParams: { businessId },
          body: {
            tokenType: "ACCOUNT_WIDGET",
            currency: selected?.currency ?? "USD",
          },
        })
        .then((resp) => {
          // console.log("resp", resp?.data);
          setTokenDetails(resp?.data);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   if (selected) {
  //     createApplicationToken();
  //   }
  // }, [selected]);

  return (
    <>
      <CardBody className="pt-5">
        <EmptyStateRoot isEmpty={wallets.length === 0 && !isLoading}>
          <EmptyStateContent>
            <div className="flex flex-col items-center">
              <img
                src={empty_state_no_cards}
                alt="Nope!"
                className="aspect-[3/2] max-w-[30ch] object-contain"
              />

              <div className="flex flex-col items-center gap-2">
                <EmptyStateDescription className="py-2 text-gray-200">
                  You have no wallet
                </EmptyStateDescription>

                <Link to="/dashboard/wallet/spend/setup">
                  <Badge type={"info"} size="sm" showIcon={false}>
                    Setup up a cash wallet
                  </Badge>
                </Link>
              </div>
            </div>
          </EmptyStateContent>
          <EmptyStateConceal>
            <div className="-mx-1 mt-[20px] flex flex-col gap-3">
              <SkeletonContent
                isLoading={isLoading}
                Component={CardLikeButtonSkeleton}
              >
                {wallets.map((item) => {
                  const currency = getFiatCurrency(item.currency);
                  return (
                    <ClickableCard
                      key={currency.code}
                      className={`items-center justify-between ${
                        selected?.currency === item?.currency &&
                        "border-[#0094FF]"
                      } px-4 py-4 `}
                      //   disabled={item.currency !== "NGN"}
                      onClick={() => {
                        setSelected(item);
                      }}
                    >
                      <div>
                        <div className="flex gap-2">
                          <img
                            alt={currency.name}
                            src={currency.flagUrl}
                            className="aspect-[20/13.3] w-[20px] object-contain"
                          />
                          <span className="text-[20px] font-semibold text-gray-600">
                            {currency.code}
                          </span>
                        </div>
                        <div className="text-neutral-500">{currency.name}</div>
                      </div>

                      <div className="text-[18px] font-semibold text-[#002C3D]">
                        {tokenDetails?.url &&
                        selected?.currency === item?.currency ? (
                          <div
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                            className="flex items-center"
                          >
                            <span
                              style={{ fontSize: "18px", fontWeight: "normal" }}
                              className="font-body font-medium text-gray-800"
                            >
                              {truncateString(`${tokenDetails?.url}`, 20)}{" "}
                              <br />
                            </span>
                            <div className="z-2 pl-2">
                              <CopyToClipboard
                                text={`${tokenDetails?.url}`}
                                onCopy={() => {
                                  notify("success", "Request link copied");
                                }}
                              >
                                <button type="button" className={""}>
                                  <CopyIcon h="14" w="14" color="#4084B5" />
                                </button>
                              </CopyToClipboard>
                            </div>
                          </div>
                        ) : (
                          <Button
                            type="button"
                            size="sm"
                            onClick={() => {
                              createApplicationToken();
                            }}
                          >
                            Generate
                          </Button>
                        )}
                        {/* {formatNumber(item.balance, { decimal: true })} */}
                      </div>
                    </ClickableCard>
                  );
                })}

                {/* <DialogRemoteTrigger
                  modalId={"add_web_hook"}
                  params={{
                    mode: "create",
                  }}
                  className=""
                  disabled={!currency}
                >
                  <Button
                    type="button"
                    size="lg"
                    variant="default"
                    className="mx-32 mt-5"
                    disabled={!currency}
                  >
                    Continue
                  </Button>
                </DialogRemoteTrigger> */}
              </SkeletonContent>
            </div>
          </EmptyStateConceal>
        </EmptyStateRoot>
      </CardBody>
      {/* <AccountWidget selected={selected} /> */}
    </>
  );
}

function CardLikeButtonSkeleton() {
  return (
    <ClickableCard className="justify-between" disabled>
      <div className="flex basis-1/2 flex-col space-y-2">
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-12" />
          <Skeleton className="h-4 basis-7/12 rounded" />
        </div>
        <Skeleton className="h-3 w-full rounded" />
      </div>

      <Skeleton className="h-7 w-24 basis-4/12 rounded" />
    </ClickableCard>
  );
}

function AccountWidget({ selected }: { selected: Wallet }) {
  const iframeRef = useRef(null);
  const modal = useModalHandle("add_web_hook");
  const businessId = useGetBusinessId();
  const navigate = useNavigate();

  const { refetch } = useGetLinkedAccounts(
    {
      pathParams: {
        businessId,
        currency: "USD",
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );

  const mutation = useCreateApplicationTokenAccount();

  const [tokenDetails, setTokenDetails] = useState<AppToken>();

  const createApplicationToken = async () => {
    try {
      await mutation
        .mutateAsync({
          pathParams: { businessId },
          body: {
            tokenType: "ACCOUNT_WIDGET",
            currency: selected?.currency ?? "USD",
          },
        })
        .then((resp) => {
          // console.log("resp", resp?.data);
          setTokenDetails(resp?.data);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selected) {
      createApplicationToken();
    }
  }, [selected]);

  useEffect(() => {
    const handleMessage = async (event) => {
      if (event.data === "close-iframe") {
        await refetch();
        navigate({
          to: "/dashboard/wallet/spend",
        });
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <DialogRemote
      id="add_web_hook"
      callback={async () => {
        await refetch();
        navigate({
          to: "/dashboard/wallet/spend",
        });
      }}
    >
      <DialogContent className={"h-full max-w-full bg-[#F2F6F8] p-0 font-body"}>
        <DialogHeader className="hidden">
          <DialogTitle />
          <DialogDescription />
        </DialogHeader>

        {tokenDetails?.url && (
          <iframe
            ref={iframeRef}
            title="Account Widget"
            src={tokenDetails?.url}
            width="100%"
            height="100%"
          />
        )}
      </DialogContent>
    </DialogRemote>
  );
}
