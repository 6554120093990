import React from "react";
import { PayoutCtx } from "..";
import { RecipientCtx } from ".";
import { Button } from "~/@/components/ui/button";
import { Input } from "~/@/components/ui/input";
import * as Address from "~/components/Inputs/CountryAddressFields";
import * as RadioPrimitive from "@radix-ui/react-radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "~/@/components//ui/form";
import { CardSeparator } from "~/components/layouts/Card";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { CurrencyDropdown } from "~/components/Inputs";
import { useCountries } from "~/hooks";
import { CountryI } from "~/general/interfaces";
import { useGetLinkedAccounts } from "~/api/codegen/liquidityComponents";
import { useGetBusinessId } from "~/hooks/use-business";
import { LinkedAccountType } from "../StepRecipient";
import { ChoiceType } from "~/libs/types.helper";
import { notify } from "~/components/Toast";

const schema = z.object({
  selectedCountry: z.string(),
  selectedCurrency: z.string(),
  // accountType: z.string().optional(),
  method: z.string().optional(),
  routingNumber: z.string().optional(),
  bank: z.string().optional(),
  accountNumber: z.string(),
  accountName: z.string(),
});

const BankDetails = ({
  currentMove,
  setCurrentMove,
  setCurrentStep,
  currentStep,
}) => {
  const { updateState, payoutState } = useContext(PayoutCtx);
  const { resetState, recipientState } = useContext(RecipientCtx);
  const businessId = useGetBusinessId();
  const { data: countries } = useCountries();
  const [accountName, setAccountName] = useState<string>("");
  const [currency, setCurrency] = React.useState<CountryI>(
    countries.filter(
      (val: CountryI) => val.name === payoutState?.country?.name,
    )[0],
  );
  const form = useForm<z.infer<typeof schema>>();

  const { data, isLoading, refetch } = useGetLinkedAccounts(
    {
      pathParams: {
        businessId,
        currency: payoutState?.country?.currency,
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );

  // const mutation = useAddLinkedAccountBankNgn();

  useEffect(() => {
    form.setValue("selectedCountry", payoutState?.country?.iso2);
    form.setValue("selectedCurrency", payoutState?.country?.currency);
  }, [payoutState?.country, currency]);

  const switchType = (type: string) => {
    switch (type) {
      case "checking":
        return "CHECKING";
      case "savings":
        return "SAVINGS";
      default:
        return "";
    }
  };

  const getNGNBanks = useMemo(() => {
    const bankDate = payoutState?.paymentMethod?.next?.data ?? [];
    const banks = bankDate
      .filter((val) => val?.type === "object")[0]
      ?.data?.filter((bank) => bank?.type === "choice")[0]?.options;
    return banks ?? [];
  }, [payoutState?.paymentMethod]);

  const watchMethod = form.watch("method");

  const linkedAccounts = useMemo(() => {
    return data?.data ?? [];
  }, [data]);

  const getAccount = () => {
    const newAccount = linkedAccounts?.filter(
      (account: LinkedAccountType) => account?.account?.label === accountName,
    );
    updateState("recipient", newAccount[0]);
    setCurrentStep(currentStep + 1);
    resetState();

    return newAccount[0];
  };

  useEffect(() => {
    if (accountName && isLoading === false) {
      getAccount();
    }
  }, [accountName, isLoading]);

  return (
    <div className="px-10">
      <div className="mb-5 px-2 text-left">
        <h2 className="py-5 text-xl font-normal text-muted-foreground">
          Enter Account Details
        </h2>
      </div>
      <form
        className="mt-[20px] flex flex-col text-left"
        onSubmit={form.handleSubmit(async (data) => {
          try {
            const bankDetailsNGN = {
              label: data.accountName,
              data: {
                name: data.accountName,
                bank_code: data.bank,
                account: data.accountNumber,
              },
            };

            const bankDetailsUSD = {
              label: data.accountName,
              data: {
                name: data.accountName,
                type: "CHECKING",
                routing: data.routingNumber,
                account: data.accountNumber,
              },
            };

            // await mutation.mutateAsync({
            //   pathParams: {
            //     token: payoutState?.tokendetails?.application?.token,
            //     currency:
            //       payoutState?.tokendetails?.application?.metadata?.currency,
            //     option: "bank",
            //     step: 1,
            //   },
            //   body:
            //     payoutState?.country?.currency === "NGN"
            //       ? bankDetailsNGN
            //       : bankDetailsUSD,
            // });
            await refetch();
            setAccountName(data.accountName);
          } catch (err) {
            console.error(err);
            notify("error", `${err.message}`);
          }
        })}
      >
        <Form {...form}>
          <div className="">
            <div>
              <div className="flex w-full items-center gap-8">
                <div className="w-full">
                  <Address.Root defaultValue={form.watch("selectedCountry")}>
                    <FormField
                      control={form.control}
                      name={"selectedCountry"}
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel>Country</FormLabel>
                            <FormControl>
                              <Address.CountryField
                                {...field}
                                value={`${payoutState?.country?.iso2}`}
                                disabled={true}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </Address.Root>
                </div>
                <div className="w-full">
                  <Address.Root defaultValue={form.watch("selectedCountry")}>
                    <FormField
                      control={form.control}
                      name={"selectedCurrency"}
                      render={({ field }) => {
                        return (
                          <FormItem className="w-full">
                            <FormLabel>Currency of Account</FormLabel>
                            <FormControl>
                              <CurrencyDropdown
                                value={currency}
                                onValueChange={(val) => {
                                  setCurrency(val);
                                  form.setValue(
                                    "selectedCountry",
                                    payoutState?.country?.iso2,
                                  );
                                }}
                                clasNmae="w-full justify-between bg-white"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </Address.Root>
                </div>
              </div>
              {/* {payoutState?.country?.currency !== "NGN" && (
                <div className="mt-8 w-full items-center gap-8">
                  <div className="w-full">
                    <FormField
                      control={form.control}
                      name={"accountType"}
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel>Account Type</FormLabel>
                            <FormControl>
                              <RadioPrimitive.Root
                                className="flex flex-col gap-2.5"
                                aria-label="View density"
                                onValueChange={(value) => {
                                  form.setValue("accountType", value);
                                }}
                              >
                                <div className="flex items-center gap-8 ">
                                  <div className="flex flex-1 items-center rounded-lg border px-3 py-2">
                                    <RadioPrimitive.Item
                                      className="h-[25px] w-[25px] cursor-pointer rounded-full border-2 bg-white outline-none hover:bg-gray-50 focus:shadow-[0_0_0_2px] focus:shadow-black"
                                      value="checking"
                                      id="r1"
                                    >
                                      <RadioPrimitive.Indicator className="relative flex h-full w-full items-center justify-center after:block after:h-[11px] after:w-[11px] after:rounded-[50%] after:bg-[#4576C2] after:content-['']" />
                                    </RadioPrimitive.Item>
                                    <label
                                      className="cursor-pointer pl-[15px] text-[15px] leading-none text-gray-600"
                                      htmlFor="r1"
                                    >
                                      Checking
                                    </label>
                                  </div>
                                  <div className="flex flex-1 items-center rounded-lg border px-3 py-2">
                                    <RadioPrimitive.Item
                                      className="hover:bg-violet3 h-[25px] w-[25px] cursor-pointer rounded-full border-2 bg-white outline-none focus:shadow-[0_0_0_2px] focus:shadow-black"
                                      value="savings"
                                      id="r2"
                                    >
                                      <RadioPrimitive.Indicator className="relative flex h-full w-full items-center justify-center after:block after:h-[11px] after:w-[11px] after:rounded-[50%] after:bg-[#4576C2] after:content-['']" />
                                    </RadioPrimitive.Item>
                                    <label
                                      className="cursor-pointer pl-[15px] text-[15px] leading-none text-gray-600"
                                      htmlFor="r2"
                                    >
                                      Savings
                                    </label>
                                  </div>
                                </div>
                              </RadioPrimitive.Root>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                </div>
              )} */}
            </div>
            <div>
              <h2 className="pt-8 text-base font-normal text-gray-400">
                Account Details
              </h2>
              <CardSeparator className="my-2 mb-5" />

              {payoutState?.country?.currency !== "NGN" && (
                <FormField
                  control={form.control}
                  name={"method"}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Method</FormLabel>
                        <FormControl>
                          <Select
                            onValueChange={(value) => {
                              form.setValue("method", value);
                            }}
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Method Type" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="ach">ACH</SelectItem>
                              <SelectItem value="wire">WIRE</SelectItem>
                              {payoutState?.country?.iso2 !== "US" && (
                                <SelectItem value="swift">SWIFT</SelectItem>
                              )}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              )}

              <div className="my-5" />

              {payoutState?.country?.currency === "NGN" && (
                <FormField
                  control={form.control}
                  name={"bank"}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Bank</FormLabel>
                        <FormControl>
                          <Select
                            onValueChange={(value) => {
                              form.setValue("bank", value);
                            }}
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Select Bank" />
                            </SelectTrigger>
                            <SelectContent>
                              {/* @ts-expect-error */}
                              {getNGNBanks?.map((bank: ChoiceType) => (
                                <SelectItem
                                  key={bank?.value}
                                  value={bank?.value}
                                >
                                  {bank?.key}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              )}

              {payoutState?.country?.currency !== "NGN" && (
                <FormField
                  control={form.control}
                  name={"routingNumber"}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>
                          {watchMethod && watchMethod === "swift"
                            ? "SWIFT/BIC Code"
                            : "Routing Number"}
                        </FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder={
                              watchMethod && watchMethod === "swift"
                                ? "ENGMYEWOXXX"
                                : "021000021"
                            }
                            className="w-full"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              )}

              <div className="my-5" />
              <FormField
                control={form.control}
                name={"accountNumber"}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>{`${
                        watchMethod && watchMethod === "swift" ? "IBAN / " : ""
                      }Account Number`}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="056008849"
                          className="w-full"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <div className="my-5" />
              <FormField
                control={form.control}
                name={"accountName"}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Account Name</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="" className="w-full" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>
          </div>

          <div className="mt-5 flex items-center justify-between">
            <Button
              size="lg"
              variant="outline"
              className="mt-5 w-[15%]"
              onClick={() => {
                setCurrentMove(currentMove - 1);
              }}
            >
              Back
            </Button>
            <Button
              size="lg"
              variant="default"
              type="submit"
              className="mt-5 w-[15%]"
              // disabled={!form.formState.isDirty}
            >
              <LoadingButtonContent loading={false}>Next</LoadingButtonContent>
            </Button>
          </div>
        </Form>
      </form>
    </div>
  );
};

export default React.memo(BankDetails);
