import {
  FileRoutesByPath,
  Link,
  ToOptions,
  createFileRoute,
  useNavigate,
} from "@tanstack/react-router";
import {
  PlusIcon,
  Info,
  ArrowDownToLine,
  Pencil,
  Send,
  Trash,
  EditIcon,
} from "lucide-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyIcon, ArrowTopRight, BankIcon } from "~/assets/icons";
import React from "react";
import {
  EmptyStateConceal,
  EmptyStateContent,
  EmptyStateRoot,
} from "~/@/components/custom/empty-state";
import { notify } from "~/components/Toast";
import {
  useGetLinkedAccounts,
  useGetLinkedAccountsByCurrencies,
  useGetTokens,
} from "~/api/codegen/liquidityComponents";
import { CardSeparator } from "~/components/layouts/Card";
import { truncateString } from "~/libs/string.helper";
import { WalletTransactionModal } from "~/components/TransactionDetails";
import { EditPaymentLink } from "~/components/Modal/EditPaymentLink";
import { DefaultInputField } from "~/components/Inputs";
import { useModal, useModalHandle } from "~/hooks/use-modal";
import { CaretDown } from "~/assets/icons";
import { EllipsisVertical } from "~/assets/icons";
import { AppTable } from "~/components/table/table";
import { TablePagination } from "~/components/organisms/table/table-pagination";
import {
  EmptyStateDescription,
  EmptyStateOverlay,
} from "~/components/molecules/empty-state";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/@/components/ui/tabs";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";
import { TabButton } from "~/components/Tabs";
import {
  CardFooter,
  CardHeader,
  CardRoot,
  CardTitle,
} from "~/components/layouts/Card";
import { NumberBadge, StatusDot } from "~/components/Badges";
import {
  useBusinessTransactions,
  useGetBusinessId,
  useAllBusinessTransactions,
} from "~/hooks/use-business";
import {
  amountColumn,
  createdAtColumn,
  receiverColumn,
  senderColumn,
  initiatorColumn,
  statusColumn,
  statusDotColumn,
  transactionTypeColumn,
} from "~/components/table/transaction";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { WalletImpl } from "~/libs/factories/wallet-factory";
import { Button } from "~/@/components/ui/button";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { Divider } from "~/components/Divider";
import { SectionHeading } from "~/components/atoms/heading";
import * as Balance from "~/components/organisms/BalanceCard";
import { SubwalletCard, AddWalletCard } from "~/components/subwallet-card";
import { BusinessProvider, useBusinessCtx } from "~/contexts/corporate-wallet";
import { setCurrency, useSharedCurrency } from "~/contexts/currency";
import { useConvert } from "~/hooks/use-currency-rates";
import { WalletHolderImpl, useWallets } from "~/hooks/use-wallet";
import {
  formatNumber,
  getFiatCurrency,
  getFlagFromRegionCode,
} from "~/libs/currency.helpers";
import {
  TitleSubHeading,
  TitleHeading,
} from "~/containers/Dashboard/Pages/Settings/Title";
import {
  ApplicationTokenTypes,
  RecipientAccountTypes,
} from "~/libs/types.helper";
import useSearch, { useCountries } from "~/hooks";
import { getDateFormat } from "~/libs/date.helpers";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/@/components/ui/tooltip";
import { XIcon } from "~/assets/icons/XIcon";
import { PencilEditIcon } from "~/assets/icons/PencilEditIcon";
import { TrashBinIcon } from "~/assets/icons/TrashBinIcon";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/@/components/ui/accordion";
import { CardIcon } from "~/assets/icons/CardIcon";
import { VBankIcon } from "~/assets/icons/VBankIcon";
import { ZelleIcon } from "~/assets/icons/ZelleIcon";
import TransactionsFilters from "~/components/TransactionsFilters";
import { CountryI } from "~/general/interfaces";
import RadixPopover from "~/components/RadixPopover";
import {
  statusDotColumn as statusDotColumnPaymentLink,
  nameColumn,
  urlColumn,
  amountColumn as amountColumnPaymentLink,
  dateColumn,
  statusColumn as statusColumnPaymentLink,
} from "~/components/table/paymentlink";
import { cn } from "~/@/lib/utils";
import { saveAs } from "file-saver";
import { NewPaymentLinkModal } from "~/components/Modal/NewPaymentLink";

export const Route = createFileRoute("/dashboard/wallet/collections/")({
  component: Page,
});

function Page() {
  return (
    <BusinessProvider>
      <section className="flex flex-col gap-2 pb-4 pt-8">
        <BalanceOverview />
        <QuickActions />
        <Divider className="h-[0px] w-[100%] border border-zinc-200 opacity-50" />
        <SubWallets />
        <Divider className="h-[0px] w-[100%] border border-zinc-200 opacity-50" />
        <PaymentLinks />
        <Divider className="h-[0px] w-[100%] border border-zinc-200 opacity-50" />
        <BusinessLog />
      </section>
    </BusinessProvider>
  );
}

function BalanceOverview() {
  const currency = useSharedCurrency();
  const { data: wallet_holder } = useWallets();
  const balances = WalletHolderImpl.calculateTotal(
    wallet_holder,
    (wallet) => wallet.kind === "CORPORATE",
  );
  const {
    isLoading,
    data: { amount: estimatedBalance },
  } = useConvert(currency.code, balances);
  const navigate = useNavigate();

  return (
    <Balance.Card>
      <Balance.Header headingText="Aggregated Balance" />
      <Balance.Content>
        <Balance.CurrencySwitcher
          currency={currency}
          setCurrency={setCurrency}
          readOnly={true}
        />
        <Balance.Balance
          isLoading={isLoading}
          balance={estimatedBalance}
          size="lg"
          currency={currency}
        />
      </Balance.Content>
    </Balance.Card>
  );
}

function QuickActions() {
  const { data: wallet } = useWallets();
  const wallets = Array.from(
    WalletHolderImpl.toList(wallet, (wallet) => wallet.kind === "CORPORATE"),
  );
  const navigate = useNavigate();
  return (
    <>
      <SectionHeading>
        <span className="py-3 text-gray-500">Quick Actions</span>
      </SectionHeading>
      <Balance.Card>
        <div className="flex flex-col items-center justify-center gap-2 px-4">
          <Button
            className="w-[60%]"
            onClick={() => {
              navigate({
                to: "/dashboard/wallet/collections/amount",
              });
            }}
          >
            <PlusIcon />
            Move to spend
          </Button>
        </div>
      </Balance.Card>
    </>
  );
}

function SubWallets() {
  const { current_wallet } = useBusinessCtx();
  const { data, isLoading } = useWallets();
  const navigate = useNavigate();

  const wallets = Array.from(
    WalletHolderImpl.toList(data, (wallet) => wallet.kind === "CORPORATE"),
  );

  const tabs = [
    { label: "Cash", value: "cash", isActive: true },
    { label: "Crypto", value: "crypto", isActive: false },
    { label: "Collectibles", value: "collectibles", isActive: false },
  ];

  return (
    <>
      <Tabs defaultValue={"cash"} className="">
        <SectionHeading className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="px-2">Wallets</span>
            <NumberBadge label="pending" count={wallets.length} />
          </div>
          <TabsList className={"gap-x-5 bg-transparent"}>
            {tabs.map((e) => (
              <TabsTrigger
                key={e.label}
                value={e.value}
                asChild
                disabled={!e.isActive}
              >
                <TabButton>{e.label}</TabButton>
              </TabsTrigger>
            ))}
          </TabsList>
        </SectionHeading>

        <TabsContent value={"cash"}>
          <EmptyStateRoot isEmpty={wallets.length === 0 && !isLoading}>
            <EmptyStateContent className="block">
              <div className="grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3">
                <AddWalletCard
                  type={"button"}
                  className="h-[7rem] border-dashed border-gray-200 bg-[white]/[10%]"
                  onClick={() => {
                    navigate({
                      to: "/dashboard/wallet/spend/setup",
                    });
                  }}
                />
              </div>
            </EmptyStateContent>
            <EmptyStateConceal>
              <div className="grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3">
                {wallets.map((wallet) => {
                  return (
                    <SubwalletCard
                      key={wallet.id}
                      type={"button"}
                      currency={getFiatCurrency(wallet.currency)}
                      balance={wallet.balance}
                      onClick={() => {
                        navigate({
                          to: "/dashboard/wallet/spend/subwallets/$id" as keyof FileRoutesByPath,
                          params: { id: wallet.id },
                        } as unknown as ToOptions);
                      }}
                    />
                  );
                })}
                <AddWalletCard
                  type={"button"}
                  className="h-[7rem] border-dashed border-gray-200 bg-[white]/[10%]"
                  onClick={() => {
                    navigate({
                      to: "/dashboard/wallet/spend/setup",
                    });
                  }}
                />
              </div>
            </EmptyStateConceal>
          </EmptyStateRoot>
        </TabsContent>
        <TabsContent value={"crypto"}>
          <EmptyStateRoot isEmpty={wallets.length !== 0 && !isLoading}>
            <EmptyStateContent className="block">
              <div className="grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3">
                <AddWalletCard
                  type={"button"}
                  className="h-[7rem] border-dashed border-gray-200 bg-[white]/[10%]"
                  onClick={() => {
                    navigate({
                      to: "/dashboard/wallet/spend/setup",
                    });
                  }}
                />
              </div>
            </EmptyStateContent>

            <EmptyStateConceal>
              <div className="grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3">
                {wallets.map((wallet) => {
                  return (
                    <SubwalletCard
                      key={wallet.id}
                      type={"button"}
                      currency={getFiatCurrency(wallet.currency)}
                      balance={wallet.balance}
                      onClick={() => {
                        navigate({
                          to: "/dashboard/wallet/spend/subwallets/$id" as keyof FileRoutesByPath,
                          params: { id: wallet.id },
                        } as unknown as ToOptions);
                      }}
                    />
                  );
                })}
                <AddWalletCard
                  type={"button"}
                  className="h-[7rem] border-dashed border-gray-200 bg-[white]/[10%]"
                  onClick={() => {
                    navigate({
                      to: "/dashboard/wallet/spend/setup",
                    });
                  }}
                />
              </div>
            </EmptyStateConceal>
          </EmptyStateRoot>
        </TabsContent>
        <TabsContent value={"collectibles"}>
          <EmptyStateRoot isEmpty={wallets.length !== 0 && !isLoading}>
            <EmptyStateContent className="block">
              <div className="grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3">
                <AddWalletCard
                  type={"button"}
                  className="h-[7rem] border-dashed border-gray-200 bg-[white]/[10%]"
                  onClick={() => {
                    navigate({
                      to: "/dashboard/wallet/spend/setup",
                    });
                  }}
                />
              </div>
            </EmptyStateContent>

            <EmptyStateConceal>
              <div className="grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3">
                {wallets.map((wallet) => {
                  return (
                    <SubwalletCard
                      key={wallet.id}
                      type={"button"}
                      currency={getFiatCurrency(wallet.currency)}
                      balance={wallet.balance}
                      onClick={() => {
                        navigate({
                          to: "/dashboard/wallet/spend/subwallets/$id" as keyof FileRoutesByPath,
                          params: { id: wallet.id },
                        } as unknown as ToOptions);
                      }}
                    />
                  );
                })}
                <AddWalletCard
                  type={"button"}
                  className="h-[7rem] border-dashed border-gray-200 bg-[white]/[10%]"
                  onClick={() => {
                    navigate({
                      to: "/dashboard/wallet/spend/setup",
                    });
                  }}
                />
              </div>
            </EmptyStateConceal>
          </EmptyStateRoot>
        </TabsContent>
      </Tabs>
    </>
  );
}

function PaymentLinks() {
  const navigate = useNavigate();
  const modal = useModal();
  const businessId = useGetBusinessId();
  const [page, setPage] = useState<number>(1);
  const [accountPage, setAccountPage] = useState<number>(1);
  const { data: wallet } = useWallets();

  const { data, isLoading, refetch } = useGetTokens(
    {
      pathParams: {
        businessId,
      },
      queryParams: {
        type: "CHECKOUT_WIDGET",
        page: page,
        limit: 5,
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );

  const wallets = Array.from(
    WalletHolderImpl.toList(wallet, (wallet) => wallet.kind === "CORPORATE"),
  );

  const {
    data: accountData,
    isLoading: accountLoading,
    refetch: accountRefetch,
  } = useGetLinkedAccountsByCurrencies(
    {
      pathParams: {
        businessId,
      },
      queryParams: {
        currencies: wallets?.map((w) => w.currency).join(","),
        page: accountPage,
        limit: 5,
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );

  useEffect(() => {
    refetch();
    accountRefetch();
  }, []);

  const appTokens = useMemo(() => {
    return data?.data ?? [];
  }, [data, isLoading]);

  const tokenMete = useMemo(() => {
    return (
      data?.meta ?? {
        page: 0,
        pageCount: 0,
        pageSize: 0,
        total: 0,
      }
    );
  }, [data]);

  const table_columns = useMemo(
    () => [
      statusDotColumnPaymentLink,
      nameColumn,
      amountColumnPaymentLink,
      dateColumn,
      statusColumnPaymentLink,
      urlColumn,
      // actionColumn,
    ],
    [],
  );

  const recipientAccount = useMemo(() => {
    return accountData?.data ?? [];
  }, [accountData]);

  const recipientAccountMeta = useMemo(() => {
    return (
      accountData?.meta ?? {
        page: 0,
        pageCount: 0,
        pageSize: 0,
        total: 0,
      }
    );
  }, [accountData]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [accountTooltip, setAccountTooltip] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [sourceTable, setSourceTable] = useState(""); // New state to track which table the data is from

  const handleRowClick = (data, tableName) => {
    console.log(
      data,
      "from handleRowClick",
      `/dashboard/wallet/spend/token/${data?.application?.token}`,
    );
    if (tableName) {
      setSelectedData(data);
      setSourceTable(tableName);
      setIsDialogOpen(true);
      navigate({
        to: `/dashboard/wallet/spend/token/${data?.application?.token}`,
      });
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedData(null);
    setSourceTable("");
  };

  const [isNewPaymentLinkModalOpen, setIsNewPaymentLinkModalOpen] =
    useState(false);

  return (
    <>
      <div className="space-y-6">
        {/* Payment Links Table */}
        <Balance.Card>
          <div>
            <div className="flex flex-wrap items-center justify-between gap-2 md:flex-nowrap">
              <SectionHeading className="font-semibold">
                Payment Links{" "}
                <RadixPopover
                  align="center"
                  side="right"
                  open={showTooltip}
                  variant="max-w-72 min-w-40 ml-2"
                  setOpen={() => setShowTooltip(!showTooltip)}
                  button={<Info style={{ color: "#3EAEFF" }} size="0.9rem" />}
                >
                  <p className="max-w-30 text-grey-800 break-words rounded-xl p-3 text-sm font-medium shadow-lg">
                    Payment Links
                  </p>
                </RadixPopover>
              </SectionHeading>

              <Button
                variant="outline"
                className="bg-[#FAFAFA] px-10"
                onClick={() => {
                  setIsNewPaymentLinkModalOpen(true);
                }}
              >
                <LoadingButtonContent Icon={<PlusIcon size="0.9rem" />}>
                  Create Payment Link
                </LoadingButtonContent>
              </Button>
            </div>
            <CardSeparator className="mt-5" />
            <EmptyStateRoot isEmpty={appTokens.length === 0 || isLoading}>
              <EmptyStateContent>
                <div className="flex h-60 flex-col items-center justify-center">
                  <EmptyStateDescription className="text-gray-500">
                    No payment link created yet
                  </EmptyStateDescription>
                </div>
              </EmptyStateContent>
              <EmptyStateConceal>
                <div className="relative w-full overflow-auto">
                  <AppTable
                    data={appTokens ?? []}
                    isLoading={isLoading}
                    columns={table_columns}
                    onRowClick={(row) => {
                      handleRowClick(
                        data?.data[row.index],
                        "Payment Link Details",
                      );
                    }}
                  />
                </div>
                <TablePagination
                  data={{
                    page: tokenMete?.page,
                    pageCount: tokenMete?.pageCount,
                    pageSize: tokenMete?.pageSize,
                    total: tokenMete?.total,
                    setPage: setPage,
                  }}
                />
              </EmptyStateConceal>
            </EmptyStateRoot>
          </div>
        </Balance.Card>

        {/* Recipients Table */}
        <Balance.Card>
          <div>
            <div className="flex flex-wrap items-center justify-between gap-2 md:flex-nowrap">
              <SectionHeading className="flex items-center font-semibold">
                Recipients
                <RadixPopover
                  align="center"
                  side="right"
                  open={accountTooltip}
                  variant="max-w-72 min-w-40 ml-2"
                  setOpen={() => setAccountTooltip(!accountTooltip)}
                  button={
                    <p className="text-black">
                      <Info style={{ color: "#3EAEFF" }} size="0.9rem" />
                    </p>
                  }
                >
                  <p className="max-w-30 text-grey-800 break-words rounded-xl p-3 text-sm font-medium shadow-lg">
                    Recipient Accounts
                  </p>
                </RadixPopover>
              </SectionHeading>
              <div className="flex items-center space-x-2">
                <Link
                  to="/dashboard/wallet/spend/addAccount"
                  disabled={accountLoading || wallets.length === 0}
                >
                  <Button variant="outline" className="bg-[#FAFAFA] px-10">
                    <LoadingButtonContent Icon={<PlusIcon size="0.9rem" />}>
                      Add Recipients
                    </LoadingButtonContent>
                  </Button>
                </Link>
              </div>
            </div>
            <CardSeparator className="mt-5" />
            <EmptyStateRoot isEmpty={recipientAccount.length === 0}>
              <EmptyStateContent>
                <div className="flex h-60 flex-col items-center justify-center">
                  <EmptyStateDescription className="text-gray-500">
                    No recipient account added yet
                  </EmptyStateDescription>
                </div>
              </EmptyStateContent>
              <EmptyStateConceal>
                <div className="overflow-x-auto">
                  <table className="w-full caption-bottom text-sm">
                    <thead className="bg-[#F2F6F8] [&_tr]:border-b">
                      <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                        <th className="h-12 px-4 text-left align-middle font-medium font-semibold text-gray-600 [&:has([role=checkbox])]:pr-0">
                          Name
                        </th>
                        <th className="h-12 px-4 text-left align-middle font-medium font-semibold text-gray-600 [&:has([role=checkbox])]:pr-0">
                          Nick Name
                        </th>
                        <th className="h-12 px-4 text-left align-middle font-medium font-semibold text-gray-600 [&:has([role=checkbox])]:pr-0">
                          Type
                        </th>
                        <th className="px-6 py-3 text-left align-middle text-xs font-medium tracking-wider text-gray-600">
                          <div>
                            <span className="text-sm font-bold leading-tight text-gray-600">
                              Detail
                            </span>
                            <span className="block text-gray-400">Issuer</span>
                          </div>
                        </th>
                        <th className="px-4 py-3 text-left align-middle font-medium font-semibold tracking-wider text-gray-600">
                          Currency
                        </th>
                        <th className="px-6 py-3" />
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {recipientAccount.map(
                        (account: RecipientAccountTypes) => (
                          <tr
                            key={account.id}
                            onClick={() =>
                              handleRowClick(account, "Recipient Profile")
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.key === " ") {
                                handleRowClick(account, "Recipient Profile");
                              }
                            }}
                          >
                            <td className="flex items-center whitespace-nowrap px-4 py-4">
                              <div
                                className="mr-3 flex h-8 w-8 items-center justify-center rounded-full"
                                style={{
                                  backgroundColor: "#E2F1FC",
                                  color: "#303940",
                                }}
                              >
                                {account.account.label
                                  .split(" ")
                                  .slice(0, 2)
                                  .map((word) => word[0])
                                  .join("")
                                  .toUpperCase()}
                              </div>
                              <span>{account.account.label}</span>
                            </td>

                            <td className="whitespace-nowrap px-4 py-4">
                              {account.account.label.toLowerCase()}
                            </td>
                            <td className="whitespace-nowrap px-4 py-4 font-bold">
                              {account.optionType.charAt(0).toUpperCase() +
                                account.optionType.slice(1)}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              <div>
                                <span className="block font-bold text-gray-800">
                                  **** {account.account.lastFourDigits}
                                </span>
                                <span className="block text-gray-400">
                                  {"-"}
                                </span>
                              </div>
                            </td>
                            <td className="flex items-center whitespace-nowrap px-4 py-4">
                              <img
                                src={getFlagFromRegionCode(
                                  account.currency.slice(0, -1),
                                )}
                                className="mr-[5px] h-[20px] w-[22px] rounded"
                                alt={`${account.currency} flag`}
                              />
                              <span className="text-xs font-normal leading-tight text-stone-600">
                                {account.currency}
                              </span>
                            </td>

                            <td className="whitespace-nowrap px-4 py-4 text-right text-sm font-medium">
                              <Send
                                absoluteStrokeWidth={false}
                                size={"1.2rem"}
                                stroke={"#C4CCD4"}
                                strokeWidth={1.8}
                              />
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>

                  {isDialogOpen && (
                    <div className="fixed inset-0 z-50 flex items-start justify-end bg-black bg-opacity-50">
                      <div className="h-full w-2/6 overflow-auto bg-white p-6 shadow-lg">
                        <div className="mb-4 flex items-center justify-between pb-4">
                          <h2 className="text-lg" style={{ color: "#0094FF" }}>
                            {sourceTable}
                          </h2>
                          <button
                            type="button"
                            onClick={closeDialog}
                            className="text-gray-500 hover:text-gray-700"
                          >
                            <XIcon size="25" color="#0094FF" />
                          </button>
                        </div>
                        <CardSeparator className="-mx-2" />

                        {sourceTable === "Payment Link Details" && (
                          <div className="flex h-[120px] w-[100%] flex-col items-center justify-center gap-[10px] rounded-lg bg-[#F2F6F8]">
                            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 text-base font-medium text-[#303940]">
                              {selectedData?.application?.tokenType
                                .replace(/_/g, " ")
                                .split(" ")
                                .slice(0, 2)
                                .map((word) => word[0])
                                .join("")
                                .toUpperCase()}
                            </div>

                            <span className="text-center font-body text-xl font-bold text-[#4084B5]">
                              {selectedData?.application?.tokenType?.replace(
                                /_/g,
                                " ",
                              )}
                            </span>
                          </div>
                        )}

                        <div className="mt-8 flex flex-col gap-4">
                          <Accordion
                            type="multiple"
                            className="w-full"
                            defaultValue={["item-1", "item-2", "item-3"]}
                          >
                            {sourceTable === "Payment Link Details" && (
                              <div className="rounded-md border">
                                <AccordionItem value="item-1">
                                  <AccordionTrigger className="p-2 hover:no-underline">
                                    <div className="flex items-center justify-between px-3 py-2">
                                      <h3 className="text-base text-gray-500">
                                        Details
                                      </h3>
                                      <div className="text-[32px] font-semibold text-[#002C3D]" />
                                    </div>
                                  </AccordionTrigger>
                                  <div className="border" />
                                  <AccordionContent>
                                    <div className="px-5 py-5">
                                      <div className="flex items-center justify-between pb-8 pt-2">
                                        <h3 className="text-sm font-normal text-gray-600">
                                          Payment Name
                                        </h3>
                                        <p className="text-sm font-semibold text-gray-800">
                                          {selectedData?.application?.tokenType.replace(
                                            /_/g,
                                            " ",
                                          )}{" "}
                                        </p>
                                      </div>
                                      <div className="flex items-center justify-between pb-8">
                                        <h3 className="text-sm font-normal text-gray-600">
                                          Status
                                        </h3>
                                        <p className="text-sm font-semibold text-gray-800">
                                          <div
                                            style={{
                                              backgroundColor: "#EDFDF0",
                                            }}
                                            className={
                                              "inline-flex h-[30px] items-center gap-1 rounded-2xl px-3 py-[10px]"
                                            }
                                          >
                                            <span
                                              style={{ color: "#3BB75E" }}
                                              className="mx-[5px] text-center text-[13px] font-bold capitalize"
                                            >
                                              {selectedData?.application?.valid
                                                ? "Active"
                                                : "Inactive"}
                                            </span>
                                          </div>
                                        </p>
                                      </div>
                                      <div className="flex items-center justify-between pb-8">
                                        <h3 className="text-sm font-normal text-gray-600">
                                          Created Date
                                        </h3>
                                        <p className="text-sm font-semibold text-gray-800">
                                          {getDateFormat(
                                            selectedData?.application
                                              ?.createdAt,
                                          ).dateFormat ?? "-"}
                                        </p>
                                      </div>
                                      <div className="flex items-center justify-between">
                                        <h3 className="text-sm font-normal text-gray-600">
                                          Expired Date
                                        </h3>
                                        <p className="text-sm font-semibold text-gray-800">
                                          {
                                            getDateFormat(
                                              selectedData?.application
                                                ?.expiredAt,
                                            ).dateFormat
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </AccordionContent>
                                </AccordionItem>
                              </div>
                            )}
                            {/* <CardSeparator className=" border-gray border" /> */}

                            {sourceTable === "Payment Link Details" && (
                              <div className="mt-4 rounded-md border">
                                <AccordionItem value="item-2">
                                  <AccordionTrigger className="p-2 hover:no-underline">
                                    <div className="flex items-center justify-between px-3 py-2">
                                      <h3 className="text-base text-gray-500">
                                        Link Details
                                      </h3>
                                      <div className="text-[32px] font-semibold text-[#002C3D]" />
                                    </div>
                                  </AccordionTrigger>
                                  <div className="border" />
                                  <AccordionContent>
                                    <div className="px-5 py-5">
                                      <div className="flex items-center justify-between pb-8 pt-2">
                                        <h3 className="text-sm font-normal text-gray-600">
                                          Payment Name
                                        </h3>
                                        <p className="text-sm font-semibold text-gray-800">
                                          {selectedData?.application?.tokenType.replace(
                                            /_/g,
                                            " ",
                                          )}{" "}
                                        </p>
                                      </div>
                                      <div className="flex items-center justify-between pb-8">
                                        <h3 className="text-sm font-normal text-gray-600">
                                          Amount
                                        </h3>
                                        <p className="text-sm font-semibold text-gray-800">
                                          {selectedData?.application?.metadata
                                            ?.currency === "USD"
                                            ? "$"
                                            : ""}{" "}
                                          {selectedData?.application?.metadata
                                            ?.extra?.amount ?? 0.0}
                                          {selectedData?.application?.metadata
                                            ?.extra?.amount && (
                                            <>
                                              {" "}
                                              {
                                                selectedData?.application
                                                  ?.metadata?.currency
                                              }
                                            </>
                                          )}
                                          {selectedData?.application?.metadata
                                            ?.extra?.amountLocked && (
                                            <img
                                              src="/lock.svg"
                                              alt="Lock"
                                              className="ml-2 inline-block h-[16px] w-[16px]"
                                            />
                                          )}
                                        </p>
                                      </div>
                                      <div className="flex items-center justify-between pb-8">
                                        <h3 className="text-sm font-normal text-gray-600">
                                          Data Collection
                                        </h3>
                                        <p className="text-sm text-gray-800">
                                          {selectedData?.application?.metadata?.extra?.dataCollections?.map(
                                            (item) => (
                                              <button
                                                type="button"
                                                key={item.id}
                                                className="mb-2 mr-2 rounded border px-2 py-1"
                                              >
                                                {item.charAt(0).toUpperCase() +
                                                  item.slice(1).toLowerCase()}
                                              </button>
                                            ),
                                          ) ?? "-"}
                                        </p>
                                      </div>
                                      <div className="flex items-center justify-between pb-8">
                                        <h3 className="text-sm font-normal text-gray-600">
                                          Customized URL
                                        </h3>
                                        <div className="flex items-center space-x-2">
                                          <span className="truncate text-sm font-semibold text-gray-800">
                                            {truncateString(
                                              `${selectedData?.url}`,
                                              22,
                                            )}
                                          </span>
                                          <CopyToClipboard
                                            text={`${selectedData?.url}`}
                                            onCopy={() =>
                                              notify(
                                                "success",
                                                "Checkout link copied",
                                              )
                                            }
                                          >
                                            <button
                                              type="button"
                                              className={""}
                                            >
                                              <CopyIcon
                                                h="14"
                                                w="14"
                                                color="#4084B5"
                                              />
                                            </button>
                                          </CopyToClipboard>
                                        </div>
                                      </div>

                                      <div className="flex items-center justify-between pb-8">
                                        <h3 className="text-sm font-normal text-gray-600">
                                          Partner Context
                                        </h3>
                                        <p className="align-right text-sm font-semibold text-gray-800">
                                          {"-"}
                                        </p>
                                      </div>
                                    </div>
                                  </AccordionContent>
                                </AccordionItem>
                              </div>
                            )}

                            {sourceTable === "Payment Link Details" && (
                              <div className="mt-5 flex justify-between border-gray-200 bg-white py-4">
                                <Button
                                  variant="destructive"
                                  disabled
                                  className="rounded bg-red-500 py-2 text-white"
                                >
                                  Delete
                                </Button>
                                <Button
                                  variant="outline"
                                  // disabled
                                  type="button"
                                  onClick={() => {
                                    modal.show("invite_user");
                                  }}
                                  className="text-black-700 rounded border border-gray-300 bg-white"
                                >
                                  Edit
                                </Button>
                              </div>
                            )}
                          </Accordion>
                        </div>
                        {sourceTable === "Recipient Profile" && (
                          <div className="">
                            <div className="flex h-[120px] w-[100%] flex-col items-center justify-center gap-[10px] rounded-lg bg-[#F2F6F8]">
                              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 text-base font-medium text-[#303940]">
                                {selectedData.account.label
                                  .split(" ")
                                  .slice(0, 2)
                                  .map((word) => word[0])
                                  .join("")
                                  .toUpperCase()}
                              </div>

                              <span className="text-center font-body text-xl font-bold text-[#4084B5]">
                                {selectedData?.account?.label}
                              </span>
                            </div>

                            <div className="mt-4 flex flex-col gap-4">
                              <Accordion
                                type="multiple"
                                className="w-full"
                                defaultValue={["item-1", "item-2", "item-3"]}
                              >
                                <div className="rounded-lg border-2">
                                  <AccordionItem value="item-1">
                                    <AccordionTrigger className="p-2 hover:no-underline">
                                      <div className="flex items-center justify-between px-3 py-2">
                                        <h3 className="text-base text-gray-500">
                                          Details
                                        </h3>
                                        <div className="text-[32px] font-semibold text-[#002C3D]" />
                                      </div>
                                    </AccordionTrigger>

                                    <div className="border" />
                                    <AccordionContent>
                                      <div className="px-5 py-5">
                                        <div className="flex items-center justify-between pb-8 pt-2">
                                          <h3 className="text-sm font-normal text-gray-600">
                                            Recipient Name
                                          </h3>
                                          <p className="text-sm font-semibold text-gray-800">
                                            {selectedData?.account?.label}
                                          </p>
                                        </div>
                                        <div className="flex items-center justify-between pb-8">
                                          <h3 className="text-sm font-normal text-gray-600">
                                            Type
                                          </h3>
                                          <p className="flex items-center text-sm font-semibold text-gray-800">
                                            {selectedData?.optionType.toLowerCase() ===
                                              "card" && (
                                              <>
                                                <CardIcon />
                                                &nbsp;
                                              </>
                                            )}
                                            {selectedData?.optionType.toLowerCase() ===
                                              "bank" && (
                                              <>
                                                <VBankIcon />
                                                &nbsp;
                                              </>
                                            )}
                                            {selectedData?.optionType.toLowerCase() ===
                                              "zelle" && (
                                              <>
                                                <ZelleIcon />
                                                &nbsp;
                                              </>
                                            )}
                                            {selectedData?.optionType
                                              .charAt(0)
                                              .toUpperCase() +
                                              selectedData?.optionType.slice(1)}
                                          </p>
                                        </div>
                                        <div className="flex items-center justify-between pb-8">
                                          <h3 className="text-sm font-normal text-gray-600">
                                            Nick Name
                                          </h3>
                                          <p className="text-sm font-semibold text-gray-800">
                                            {selectedData?.account?.label}
                                          </p>
                                        </div>
                                      </div>
                                    </AccordionContent>
                                  </AccordionItem>
                                </div>
                                {/* <CardSeparator className="-mx-2" /> */}

                                <div className="mt-4 rounded-lg border-2">
                                  <AccordionItem value="item-2">
                                    <AccordionTrigger className="p-2 hover:no-underline">
                                      <div className="flex items-center justify-between px-3 py-2">
                                        <h3 className="text-base text-gray-500">
                                          Account Details
                                        </h3>
                                        {/* <div className="text-[32px] font-semibold text-[#002C3D]"></div> */}
                                      </div>
                                    </AccordionTrigger>

                                    <div className="border" />
                                    <AccordionContent>
                                      <div className="px-5 py-5">
                                        <div className="flex items-center justify-between pb-8 pt-2">
                                          <h3 className="text-sm font-normal text-gray-600">
                                            Account Name
                                          </h3>
                                          <p className="text-sm font-semibold text-gray-800">
                                            {selectedData?.account?.label ??
                                              "-"}
                                          </p>
                                        </div>

                                        {selectedData?.optionType ===
                                          "card" && (
                                          <>
                                            <div className="flex items-center justify-between pb-8">
                                              <h3 className="text-sm font-normal text-gray-600">
                                                Card
                                              </h3>
                                              <p className="text-sm font-semibold text-gray-800">
                                                {"****"}{" "}
                                                {
                                                  selectedData?.account
                                                    ?.lastFourDigits
                                                }
                                              </p>
                                            </div>
                                          </>
                                        )}

                                        {selectedData?.optionType ===
                                          "bank" && (
                                          <>
                                            <div className="flex items-center justify-between pb-8">
                                              <h3 className="text-sm font-normal text-gray-600">
                                                Bank Name
                                              </h3>
                                              <p className="text-sm font-semibold text-gray-800">
                                                {"-"}
                                              </p>
                                            </div>
                                            <div className="flex items-center justify-between pb-8">
                                              <h3 className="text-sm font-normal text-gray-600">
                                                Account Number
                                              </h3>
                                              <p className="text-sm font-semibold text-gray-800">
                                                {`****${selectedData?.account?.lastFourDigits}`}
                                              </p>
                                            </div>
                                            {selectedData.currency !==
                                              "NGN" && (
                                              <div className="flex items-center justify-between pb-8">
                                                <h3 className="text-sm font-normal text-gray-600">
                                                  Routing Number
                                                </h3>
                                                <p className="text-sm font-semibold text-gray-800">
                                                  {"-"}
                                                </p>
                                              </div>
                                            )}
                                            <div className="flex items-center justify-between pb-8">
                                              <h3 className="text-sm font-normal text-gray-600">
                                                Account Type
                                              </h3>
                                              <p className="text-sm font-semibold text-gray-800">
                                                {"-"}
                                              </p>
                                            </div>
                                          </>
                                        )}

                                        <div className="flex items-center justify-between pb-8 pt-2">
                                          <h3 className="text-sm font-normal text-gray-600">
                                            Currency
                                          </h3>
                                          <p className="text-sm font-semibold text-gray-800">
                                            <span className="flex items-center text-sm font-medium leading-normal">
                                              <img
                                                src={getFlagFromRegionCode(
                                                  selectedData.currency.slice(
                                                    0,
                                                    -1,
                                                  ),
                                                )}
                                                className="mr-[5px] h-[20px] w-[22px] rounded"
                                                alt={`${selectedData.currency} flag`}
                                              />
                                              <span className="text-sm font-semibold leading-tight text-stone-600">
                                                {selectedData.currency}
                                              </span>
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </AccordionContent>
                                  </AccordionItem>
                                </div>
                              </Accordion>
                              <div className="flex justify-between">
                                <Button
                                  variant="destructive"
                                  disabled
                                  className="rounded bg-red-500 py-2 text-white"
                                >
                                  Delete
                                </Button>
                                <Button
                                  variant="outline"
                                  disabled
                                  className="text-black-700 rounded border border-gray-300 bg-white"
                                >
                                  Edit
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    // </div>
                  )}
                </div>
                {/* {recipientAccountMeta.total !== 0 && ( */}
                <CardFooter className="py-3">
                  <TablePagination
                    data={{
                      page: recipientAccountMeta.page,
                      pageCount: recipientAccountMeta.pageCount,
                      pageSize: recipientAccountMeta.pageSize,
                      total: recipientAccountMeta.total,
                      setPage: setAccountPage,
                    }}
                  />
                </CardFooter>
              </EmptyStateConceal>
            </EmptyStateRoot>
          </div>
        </Balance.Card>
        <EditPaymentLink
          sourceTable={sourceTable}
          selectedData={selectedData}
        />
        <NewPaymentLinkModal
          isOpen={isNewPaymentLinkModalOpen}
          setIsOpen={setIsNewPaymentLinkModalOpen}
        />
      </div>
    </>
  );
}

function BusinessLog() {
  const [currentTab, setCurrentTab] = useState("");
  const [totalLog, setTotalLog] = useState(0);

  const tabs = [
    { label: "Transactions", value: "transactions", isActive: true },
    { label: "Admin Activity", value: "admin", isActive: false },
  ];

  return (
    <Tabs defaultValue={"transactions"} className="-mt-5 mb-10">
      <CardHeader className="mb-[0px] flex items-center justify-between px-0">
        <div className="flex">
          <CardTitle className="pr-3 font-normal text-gray-500">
            History
          </CardTitle>
          <NumberBadge label="pending" count={totalLog ?? 0} />
        </div>

        <TabsList className={"gap-x-5 bg-transparent"}>
          {tabs.map((e) => (
            <TabsTrigger
              key={e.label}
              value={e.value}
              disabled={!e.isActive}
              asChild
              onClick={() => setCurrentTab(e.value)}
            >
              <TabButton>{e.label}</TabButton>
            </TabsTrigger>
          ))}
        </TabsList>
      </CardHeader>

      <TabsContent value={"transactions"}>
        <TransactionLog setTotalLog={setTotalLog} />
      </TabsContent>
      <TabsContent value={"admin"}>
        <AdminLog setTotalLog={setTotalLog} />
      </TabsContent>
    </Tabs>
  );
}

function TransactionLog({
  title,
  setTotalLog,
}: {
  title?: string;
  setTotalLog?: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [currentTab, setCurrentTab] = useState(undefined);
  const { data: countries } = useCountries();
  const [page, setPage] = useState<number>(1);
  const [open, setOpen] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [currency, setCurrency] = React.useState<CountryI>(
    countries.filter((val: CountryI) => val.iso2 === "US")[0],
  );
  const [currencyChanged, setCurrencyChanged] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string>("");
  const [transactionMethod, setTransactionMethod] = useState<string>("");
  const [transactionStatus, setTransactionStatus] = useState<string>("");
  const [amount, setAmount] = React.useState<string>("");
  const [amountRange, setAmountRange] = React.useState<string>("");
  const [showFilter, setShowFilter] = React.useState<boolean>(false);
  const modal = useModal();
  const businessId = useGetBusinessId();
  const { data: wallet } = useWallets();

  const wallets = Array.from(
    WalletHolderImpl.toList(wallet, (wallet) => wallet.kind === "CORPORATE"),
  );

  const table_columns = useMemo(
    () => [
      statusDotColumn,
      createdAtColumn,
      senderColumn,
      receiverColumn,
      initiatorColumn,
      transactionTypeColumn,
      amountColumn,
      statusColumn,
    ],
    [],
  );
  const filteredItems = useMemo(
    () => [
      {
        title: "StartDate",
        value: fromDate ? format(fromDate, "dd/MM/yy") : "",
      },
      {
        title: "EndDate",
        value: toDate ? format(toDate, "dd/MM/yy") : "",
      },
      { title: "Method", value: transactionMethod ?? "" },
      { title: "Status", value: transactionStatus ?? "" },
      { title: "Currency", value: selectedCurrency ?? "" },
      { title: "Amt", value: amount ? `${amountRange}${amount}` : "" },
    ],
    [transactionMethod, fromDate, toDate, transactionStatus, currency, amount],
  );

  const filteredTab = useMemo(() => {
    let tab = "";

    if (currentTab === "all") {
      tab = "";
    }

    if (currentTab === "funding") {
      tab = "funding";
    }
    if (currentTab === "payout") {
      tab = "payout";
    }
    if (currentTab === "earnings") {
      tab = "earnings";
    }
    if (currentTab === "withdrawal") {
      tab = "withdrawal";
    }
    return tab;
  }, [currentTab]);

  const handleCurrencyChange = (newCurrency: CountryI) => {
    if (currency !== newCurrency) {
      setSelectedCurrency(newCurrency.currency);
    }
  };

  const { isLoading, data, refetch } = useAllBusinessTransactions(
    {
      businessId,
      page: page,
      wallets: wallets.map((w) => w.id).join(","),
      currency:
        open === false && showFilter && selectedCurrency
          ? selectedCurrency
          : "",
      amount:
        open === false && showFilter && amount && amountRange
          ? `${amountRange}${amount}`
          : "",
      startDate:
        open === false && showFilter && fromDate
          ? `${fromDate?.split("/")?.reverse()?.join("-")}`
          : "",
      endDate:
        open === false && showFilter && toDate
          ? `${toDate?.split("/")?.reverse()?.join("-")}`
          : "",
      flowType:
        (open === false && showFilter && transactionMethod
          ? transactionMethod !== "all"
            ? transactionMethod.toUpperCase()
            : ""
          : "") ||
        (open === false && filteredTab ? filteredTab.toUpperCase() : undefined),
      status:
        open === false && showFilter && transactionStatus
          ? transactionStatus
          : "",
    },
    {
      enabled: !!businessId && fromDate && open === false,
    },
  );

  const clearFilter = () => {
    setCurrentTab("all");
    setOpen(false);
    setShowFilter(false);
    setTransactionMethod("");
    setTransactionStatus("");
    setAmount("");
    setAmountRange("");
    setFromDate("");
    setToDate("");
    setCurrency(countries.filter((val: CountryI) => val.iso2 === "US")[0]);
    setSelectedCurrency("");
    refetch();
  };

  const handleFilter = () => {
    setCurrentTab("all");
    setOpen(false);
    setShowFilter(true);
  };

  const { searchTerm, handleSearchChange, resetSearch, filteredList } =
    useSearch(data.data, "flow_type", "method");

  useEffect(() => {
    setTotalLog(data.meta.total ?? 0);
  }, [data.meta]);

  const tabs = [
    { label: "All", value: "all", isActive: true },
    { label: "Funding", value: "funding", isActive: true },
    { label: "Payout", value: "payout", isActive: true },
    { label: "Withdrawal", value: "withdrawal", isActive: true },
    { label: "Earnings", value: "earnings", isActive: true },
  ];

  const downloadCSV = () => {
    if (!filteredList || filteredList.length === 0) {
      alert("No data available to download.");
      return;
    }

    const headers = Object.keys(filteredList[0]);
    const rows = filteredList.map((row) =>
      headers.map((key) => `"${row[key] || ""}"`).join(","),
    );

    const csvContent = [headers.join(","), ...rows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    saveAs(blob, "transactions.csv");
  };

  return (
    <Tabs defaultValue={"all"} className="">
      <CardRoot>
        <div
          className={
            "no-scrollbar flex w-full flex-col items-start justify-between gap-4 overflow-x-auto px-4 pb-2 pt-5 md:flex-row"
          }
        >
          <TabsList className={"gap-x-5 bg-transparent"}>
            {tabs.map((e) => (
              <TabsTrigger
                key={e.label}
                value={e.value}
                disabled={!e.isActive}
                onClick={() => setCurrentTab(e.value)}
                asChild
              >
                <TabButton>{e.label}</TabButton>
              </TabsTrigger>
            ))}
          </TabsList>

          <div className="flex flex-wrap items-center gap-4 md:flex-nowrap">
            {showFilter && (
              <div className="-mr-3 flex pb-1 text-sm font-semibold text-[#3BB75E]">
                <h1>Filtered By:</h1>
                <p className="flex pl-1">
                  {filteredItems?.map((item) => (
                    <p key={`${item.title}-${item.value}`}>
                      {item.value && <p>{`${item.title}(${item.value}),`}</p>}
                    </p>
                  ))}
                </p>
              </div>
            )}
            <TransactionsFilters
              isHistory={data.data.length === 0}
              open={open}
              setOpen={setOpen}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              clearFilter={clearFilter}
              handleFilter={handleFilter}
              transactionMethod={transactionMethod}
              setTransactionMethod={setTransactionMethod}
              transactionStatus={transactionStatus}
              setTransactionStatus={setTransactionStatus}
              amount={amount}
              setAmount={setAmount}
              amountRange={amountRange}
              setAmountRange={setAmountRange}
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              currency={currency}
              setCurrency={setCurrency}
              currentTab={currentTab}
              handleCurrencyChange={handleCurrencyChange}
              downloadCSV={downloadCSV}
            />
            {/* <Download /> */}
            <div>
              <DefaultInputField
                placeholder="Search"
                value={searchTerm}
                setValue={handleSearchChange}
                disabled={data.data.length === 0}
              />
            </div>
          </div>
        </div>

        <Divider className="my-[5px]" />

        <EmptyStateOverlay
          isEmpty={data.data.length === 0 && !isLoading}
          content={
            <EmptyStateDescription>
              Actions and Transactions will appear here
            </EmptyStateDescription>
          }
        >
          <div className="no-scrollbar overflow-auto">
            <TabsContent value={"all"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={table_columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", data.data[row.index]);
                }}
              />
            </TabsContent>
            <TabsContent value={"funding"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={table_columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", data.data[row.index]);
                }}
              />
            </TabsContent>
            <TabsContent value={"payout"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={table_columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", data.data[row.index]);
                }}
              />
            </TabsContent>
            <TabsContent value={"withdrawal"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={table_columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", data.data[row.index]);
                }}
              />
            </TabsContent>
            <TabsContent value={"earnings"}>
              <AppTable
                data={filteredList ?? []}
                isLoading={isLoading}
                columns={table_columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", data.data[row.index]);
                }}
              />
            </TabsContent>
          </div>
        </EmptyStateOverlay>

        {data.meta.total !== 0 && (
          <CardFooter className="py-3">
            <TablePagination
              data={{
                page: data.meta.page,
                pageCount: data.meta.pageCount,
                pageSize: data.meta.pageSize,
                total: data.meta.total,
                setPage: setPage,
              }}
            />
          </CardFooter>
        )}

        <WalletTransactionModal />
      </CardRoot>
    </Tabs>
  );
}

function AdminLog({
  title,
  setTotalLog,
}: {
  title?: string;
  setTotalLog?: React.Dispatch<React.SetStateAction<number>>;
}) {
  const table_columns = useMemo(
    () => [
      statusDotColumn,
      createdAtColumn,
      transactionTypeColumn,
      receiverColumn,
      senderColumn,
      amountColumn,
      statusColumn,
    ],
    [],
  );

  const [searchVal, setSearchVal] = useState("");
  const modal = useModal();
  const [open, setOpen] = React.useState(false);

  const businessId = useGetBusinessId();
  const { data: wallet_holder } = useWallets();

  const default_wallet = WalletHolderImpl.getDefaultWallet(
    wallet_holder,
    "CORPORATE",
  );

  useEffect(() => {
    setTotalLog(0);
  }, []);

  const { isLoading, data } = useBusinessTransactions(
    {
      businessId,
      walletId: WalletImpl.getId(default_wallet),
      page: 1,
      flowType: undefined,
    },
    {
      enabled: default_wallet.kind !== "NONE",
    },
  );

  const tabs = [{ label: "All", value: "all", isActive: true }];

  const clearFilter = () => {
    setOpen(false);
  };

  return (
    <Tabs defaultValue={"all"} className="">
      <CardRoot>
        <div
          className={
            "no-scrollbar flex flex-col items-start justify-between gap-4 overflow-x-auto px-4 pb-2 pt-5 md:flex-row"
          }
        >
          <TabsList className={"gap-x-5 bg-transparent"}>
            {tabs.map((e) => (
              <TabsTrigger key={e.label} value={e.value} asChild>
                <TabButton>{e.label}</TabButton>
              </TabsTrigger>
            ))}
          </TabsList>

          <div className="flex flex-wrap items-center gap-4 md:flex-nowrap">
            <TransactionsFilters
              open={open}
              setOpen={setOpen}
              clearFilter={clearFilter}
            />
            <Download />
            <DefaultInputField
              placeholder="Search"
              value={searchVal}
              setValue={setSearchVal}
            />
          </div>
        </div>

        <Divider className="my-[5px]" />

        <EmptyStateOverlay
          isEmpty={data.data.length === 0 && !isLoading}
          content={
            <EmptyStateDescription>
              Actions and Transactions will appear here
            </EmptyStateDescription>
          }
        >
          <div className="no-scrollbar overflow-auto">
            <TabsContent value={"all"}>
              <AppTable
                data={data.data}
                isLoading={isLoading}
                columns={table_columns}
                onRowClick={(row) => {
                  modal.show("transaction_details", data.data[row.index]);
                }}
              />
            </TabsContent>
          </div>
        </EmptyStateOverlay>

        {data.data.length !== 0 && (
          <CardFooter>
            <TablePagination
              data={{ page: 0, pageCount: 0, pageSize: 0, total: 0 }}
            />
          </CardFooter>
        )}

        <WalletTransactionModal />
      </CardRoot>
    </Tabs>
  );
}

export function Download() {
  const filterItems = React.useMemo(
    () => [
      {
        label: "All",
        icon: <></>,
        link: "/settings/profile",
      },
    ],
    [],
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex cursor-not-allowed items-center text-sm text-[#57584E]">
          <div className="mr-[5px]">
            <ArrowDownToLine size={12} />
          </div>
          Download
        </div>
      </DropdownMenuTrigger>

      {/* <DropdownMenuContent
        sideOffset={10}
        align="end"
        className="min-w-[150px]"
      >
        <DropdownMenuGroup>
          {filterItems.map((item, i) => (
            <DropdownMenuItem
              key={item.label}
              className="space-x-2 text-gray-700"
              onClick={() => {}}
            >
              {item.icon}
              <span className={"text-base font-normal"}>{item.label}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent> */}
    </DropdownMenu>
  );
}

export function LinkDropdown({
  handleEdit,
  handleDelete,
}: {
  handleEdit?: () => void;
  handleDelete?: () => void;
}) {
  const navigate = useNavigate();
  const filterItems = React.useMemo(
    () => [
      {
        label: "Edit",
        icon: <PencilEditIcon size="1rem" />,
        link: "/settings/profile",
        fun: handleEdit,
      },
      // {
      //   label: "Delete",
      //   icon: <TrashBinIcon size={"1rem"} />,
      //   link: "/settings/profile",
      //   fun: handleDelete,
      // },
    ],
    [handleEdit],
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="px-5">
        <div className="flex cursor-pointer items-center text-sm text-[#909090]">
          <div className="ml-[5px]">
            <EllipsisVertical />
          </div>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        sideOffset={10}
        align="end"
        className="min-w-[150px]"
      >
        <DropdownMenuGroup>
          {filterItems.map((item, i) => (
            <DropdownMenuItem
              key={item.label}
              className="space-x-2 text-gray-700"
              onClick={() => {
                item.fun();
                // navigate({
                //   to: "/dashboard/wallet/spend/setup",
                // });
              }}
            >
              {item.icon}
              <span className={"text-base font-normal"}>{item.label}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
