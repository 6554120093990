"use client";

import { useState } from "react";
import { Badge } from "~/@/components/ui/badge";
import { Button } from "~/@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { Input } from "~/@/components/ui/input";
import { Alert, AlertDescription } from "~/@/components/ui/alert";
import { fetchCreateRule } from "~/api/codegen/liquidityComponents";
import {
  ArrowLeft,
  X,
  ChevronDown,
  ChevronUp,
  User,
  Plus,
  Pencil,
  Trash2,
} from "lucide-react";
import { cn } from "~/@/lib/utils";
import { useWallets } from "~/hooks/use-wallet";
import { toast } from "react-toastify";

type TransactionType = "Funding" | "Withdrawal" | string;

interface Rule {
  id: number;
  transactionTypes: TransactionType[];
  currency: string;
  minAmount: string;
  maxAmount: string;
  requiredPeople: string[];
  walletId: string;
}

export default function AddRuleModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [rules, setRules] = useState<Rule[]>([]);
  const { data: wallets } = useWallets();
  const [currentRule, setCurrentRule] = useState<Rule>({
    id: 0,
    transactionTypes: ["Funding", "Withdrawal"],
    currency: "USD",
    minAmount: "2,000.00",
    maxAmount: "10,000.00",
    requiredPeople: ["James Smith"],
    walletId: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [newTransactionType, setNewTransactionType] = useState("");
  const [newPerson, setNewPerson] = useState("");
  const [isTransactionTypeOpen, setIsTransactionTypeOpen] = useState(false);
  const [isRequireOpen, setIsRequireOpen] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleAddRuleToDb = async () => {
    console.log("currentRule - before", currentRule);
    // console.log("wallets", wallets?.CORPORATE.USD.id);
    const updatedRule = {
      ...currentRule,
      walletId: wallets?.CORPORATE?.USD?.id ?? "", //need to be debugged
    };
    console.log("updatedRule", updatedRule);

    const res = await fetchCreateRule({
      body: {
        ...updatedRule,
      },
    });
    console.log("res from add rule to db", res);
    if (res) {
      resetForm();
      toast.success("Rule added successfully");
    }
  };

  const addOrUpdateRule = () => {
    return handleAddRuleToDb();
    // if (isEditing) {
    //   setRules(
    //     rules.map((rule) => (rule.id === currentRule.id ? currentRule : rule))
    //   );
    // } else {
    //   setRules([...rules, { ...currentRule, id: Date.now() }]);
    // }
    // resetForm();
  };

  const editRule = (rule: Rule) => {
    setCurrentRule(rule);
    setIsEditing(true);
  };

  const deleteRule = (id: number) => {
    setRules(rules.filter((rule) => rule.id !== id));
  };

  const resetForm = () => {
    setCurrentRule({
      id: 0,
      transactionTypes: ["Funding", "Withdrawal"],
      currency: "USD",
      minAmount: "2,000.00",
      maxAmount: "10,000.00",
      requiredPeople: ["James Smith"],
      walletId: "",
    });
    setIsEditing(false);
    setIsTransactionTypeOpen(false);
    setIsRequireOpen(false);
    setShowError(false);
  };

  const removeTransactionType = (type: TransactionType) => {
    setCurrentRule({
      ...currentRule,
      transactionTypes: currentRule.transactionTypes.filter((t) => t !== type),
    });
  };

  const addTransactionType = () => {
    if (
      newTransactionType &&
      !currentRule.transactionTypes.includes(newTransactionType)
    ) {
      setCurrentRule({
        ...currentRule,
        transactionTypes: [...currentRule.transactionTypes, newTransactionType],
      });
      setNewTransactionType("");
    }
  };

  const removePerson = (person: string) => {
    setCurrentRule({
      ...currentRule,
      requiredPeople: currentRule.requiredPeople.filter((p) => p !== person),
    });
  };

  const addPerson = () => {
    if (newPerson && !currentRule.requiredPeople.includes(newPerson)) {
      setCurrentRule({
        ...currentRule,
        requiredPeople: [...currentRule.requiredPeople, newPerson],
      });
      setNewPerson("");
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="h-[90vh] overflow-y-scroll sm:max-w-[600px]">
        <DialogHeader className="flex flex-row items-center border-b pb-4">
          <Button
            variant="ghost"
            size="icon"
            className="absolute left-4"
            onClick={onClose}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <DialogTitle className="flex-1 text-center text-xl font-semibold">
            {isEditing ? "Edit Rule" : "Add Rule"}
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6 py-4">
          {/* Transaction Type */}
          <div className="space-y-4">
            <label className="text-base font-medium">Transaction Type</label>
            <div className="rounded-lg border">
              <div
                className="flex cursor-pointer items-center justify-between p-2"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsTransactionTypeOpen(!isTransactionTypeOpen);
                  }
                }}
                onClick={() => setIsTransactionTypeOpen(!isTransactionTypeOpen)}
              >
                <div className="flex flex-wrap gap-2">
                  {currentRule.transactionTypes.map((type) => (
                    <Badge
                      key={type}
                      variant="gray"
                      className="gap-1 pl-3 pr-2"
                    >
                      {type}
                      <button
                        type="button"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            removeTransactionType(type);
                          }
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          removeTransactionType(type);
                        }}
                        className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                      >
                        <X className="h-3 w-3" />
                        <span className="sr-only">Remove {type}</span>
                      </button>
                    </Badge>
                  ))}
                </div>
                {isTransactionTypeOpen ? (
                  <ChevronUp className="h-4 w-4" />
                ) : (
                  <ChevronDown className="h-4 w-4" />
                )}
              </div>
              {isTransactionTypeOpen && (
                <div className="border-t p-2">
                  <Input
                    type="text"
                    value={newTransactionType}
                    onChange={(e) => setNewTransactionType(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        addTransactionType();
                      }
                    }}
                    placeholder="Type and press Enter to add"
                    className="h-8"
                  />
                </div>
              )}
            </div>
          </div>

          {/* Currency */}
          <div className="space-y-4">
            <label className="text-base font-medium">Currency</label>
            <Select
              value={currentRule.currency}
              onValueChange={(value) =>
                setCurrentRule({ ...currentRule, currency: value })
              }
            >
              <SelectTrigger>
                <SelectValue>
                  <div className="flex items-center gap-2">
                    <span className="font-medium">
                      {currentRule.currency === "USD"
                        ? "🇺🇸 USD"
                        : currentRule.currency}
                    </span>
                  </div>
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="USD">
                  <div className="flex items-center gap-2">
                    <span>🇺🇸 USD</span>
                  </div>
                </SelectItem>
                {/* Add more currency options here */}
              </SelectContent>
            </Select>
          </div>

          {/* Amount Range */}
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <span className="text-xs">If amount is</span>
              <span className="text-xs font-medium">greater or equal to</span>
              <Input
                type="text"
                value={currentRule.minAmount}
                onChange={(e) =>
                  setCurrentRule({ ...currentRule, minAmount: e.target.value })
                }
                className="w-24 text-xs font-medium"
              />
              <span className="text-xs font-medium">and less than</span>
              <Input
                type="text"
                value={currentRule.maxAmount}
                onChange={(e) =>
                  setCurrentRule({ ...currentRule, maxAmount: e.target.value })
                }
                className="w-24 text-xs font-medium"
              />
            </div>

            {showError && (
              <Alert
                variant="default"
                className="border-orange-200 bg-orange-50 text-orange-900"
              >
                <AlertDescription className="flex items-center gap-2">
                  Rule already exists.
                </AlertDescription>
              </Alert>
            )}
          </div>

          <div className="space-y-4">
            <label className="text-base font-medium">Require</label>
            <div className="rounded-lg border">
              <div
                className="flex cursor-pointer items-center justify-between p-2"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsRequireOpen(!isRequireOpen);
                  }
                }}
                onClick={() => setIsRequireOpen(!isRequireOpen)}
              >
                <div className="flex flex-wrap gap-2">
                  {currentRule.requiredPeople.map((person) => (
                    <Badge
                      key={person}
                      variant="gray"
                      className="gap-1 pl-3 pr-2"
                    >
                      <User className="mr-1 h-3 w-3" />
                      {person}
                      <button
                        type="button"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            removePerson(person);
                          }
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          removePerson(person);
                        }}
                        className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                      >
                        <X className="h-3 w-3" />
                        <span className="sr-only">Remove {person}</span>
                      </button>
                    </Badge>
                  ))}
                </div>
                {isRequireOpen ? (
                  <ChevronUp className="h-4 w-4" />
                ) : (
                  <ChevronDown className="h-4 w-4" />
                )}
              </div>
              {isRequireOpen && (
                <div className="border-t p-2">
                  <Input
                    type="text"
                    value={newPerson}
                    onChange={(e) => setNewPerson(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        addPerson();
                      }
                    }}
                    placeholder="Type and press Enter to add"
                    className="h-8"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* {rules.length > 0 && (
          <div className="mt-6 space-y-4">
            <h3 className="text-lg font-semibold">Existing Rules</h3>
            {rules.map((rule) => (
              <div
                key={rule.id}
                className="flex items-center justify-between rounded-lg border p-2"
              >
                <div>
                  <span className="font-medium">
                    {rule.transactionTypes.join(", ")}
                  </span>
                  <span className="mx-2">|</span>
                  <span>{rule.currency}</span>
                  <span className="mx-2">|</span>
                  <span>
                    {rule.minAmount} - {rule.maxAmount}
                  </span>
                </div>
                <div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        editRule(rule);
                      }
                    }}
                    onClick={() => editRule(rule)}
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => deleteRule(rule.id)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )} */}

        <div className="flex justify-between border-t pt-4">
          <Button variant="outline" onClick={resetForm}>
            Cancel
          </Button>
          <Button
            className={cn(
              "bg-blue-50 text-blue-600 hover:bg-blue-100",
              "border border-blue-200",
            )}
            onClick={addOrUpdateRule}
          >
            {isEditing ? "Update" : "Add"} Rule
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
