import React, { ComponentProps } from "react";
import { Search, ChevronRight, PlusIcon } from "lucide-react";
import {
  useGetLinkedAccounts,
  useGetLinkedAccountsByCurrencies,
} from "~/api/codegen/liquidityComponents";
import { DefaultInputField } from "~/components/Inputs";
import { useAccountData, useGetBusinessId } from "~/hooks/use-business";
import { ClickableCard } from "~/components/ClickableCard";
import { empty_state_default } from "~/assets";
import { Button } from "~/@/components/ui/button";
import { cn } from "~/@/lib/utils";
import {
  EmptyStateConceal,
  EmptyStateContent,
  EmptyStateRoot,
} from "~/@/components/custom/empty-state";
import { BankIcon } from "~/assets/icons";
import { CardIcon, BankFundingIcon } from "~/assets/icons";
import { CardSeparator } from "~/components/layouts/Card";
import { EmptyStateDescription } from "~/components/molecules/empty-state";
import { SkeletonContent } from "~/components/atoms/skeleton";
import { getFiatCurrency } from "~/libs/currency.helpers";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { NumberBadge } from "~/components/Badges";
import { PayoutCtx } from ".";
import { CardLikeButtonSkeleton } from "../Withdrawal/StepSource";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import AddRecipient from "./AddRecipient";
import { useDebounce } from "use-debounce";
import { useWallets, WalletHolderImpl } from "~/hooks/use-wallet";

export interface LinkedAccountType {
  id: string;
  currency: string;
  optionType: string;
  createdAt: string;
  account: {
    label: string;
    lastFourDigits: string;
  };
  wallet: {
    id: string;
    balance: string;
    disabled: boolean;
  };
  entity: {
    id: string;
    email: string;
    externalIdentifier: string;
    entityType: string;
  };
  application: {
    id: string;
    externalId: string;
    token: string;
    businessId: string;
  };
}

const StepRecipient = ({ completeStep, setCurrentStep, currentStep }) => {
  const businessId = useGetBusinessId();
  const { updateState, payoutState } = useContext(PayoutCtx);
  const [searchVal, setSearchVal] = React.useState("");
  const [addRecipient, setAddRecipient] = useState(false);
  const { data: wallet } = useWallets();
  const [debouncedSearch] = useDebounce(searchVal, 500);

  const wallets = Array.from(
    WalletHolderImpl.toList(wallet, (wallet) => wallet.kind === "CORPORATE"),
  );

  const optionType = (option?: string) => {
    switch (option) {
      case "Bank":
      case "Manual Bank":
        return "bank";
      case "Card":
        return "card";
      default:
        return "";
    }
  };

  const { data, isLoading } = useGetLinkedAccountsByCurrencies(
    {
      pathParams: {
        businessId,
      },
      queryParams: {
        currencies: wallets?.map((w) => w.currency).join(","),
        // optionType: optionType(payoutState?.paymentMethod.label),
        search: debouncedSearch,
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );

  const linkedAccounts = useMemo(() => {
    if (data) {
      return data?.data ?? [];
    }
    return [];
  }, [data]);

  const filteredAccounts = React.useMemo(() => {
    if (debouncedSearch === "") {
      return linkedAccounts;
    }
    return linkedAccounts?.filter((item: LinkedAccountType) =>
      item?.account?.label
        ?.toLowerCase()
        ?.includes(debouncedSearch?.toLowerCase()),
    );
  }, [debouncedSearch, linkedAccounts]);

  return (
    <>
      {addRecipient ? (
        <AddRecipient
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      ) : (
        <div className="mx-auto w-full max-w-[613px] rounded-lg bg-[white] px-10">
          <div className="mb-5 text-left">
            <h2 className="py-5 text-xl font-normal text-muted-foreground">
              Select Recipient
            </h2>
            <div className="flex items-center">
              <DefaultInputField
                placeholder="Search for recipient"
                value={searchVal}
                setValue={setSearchVal}
                icon={<Search size={"1rem"} className="text-gray-300" />}
                rtl={false}
                style={{ height: "50px" }}
              />
            </div>
          </div>
          <CardSeparator className="-mt-1 mb-5 border-dashed" />
          <div className="flex">
            <h2 className="pr-2 font-normal text-muted-foreground">
              Recipient
            </h2>
            <NumberBadge label="pending" count={filteredAccounts?.length} />
          </div>
          <EmptyStateRoot
            isEmpty={filteredAccounts?.length === 0 && !isLoading}
          >
            <EmptyStateContent>
              <div className="flex flex-col items-center">
                <img
                  src={empty_state_default}
                  alt="Nope!"
                  className="aspect-[3/2] max-w-[30ch] object-contain"
                />

                <div className="flex flex-col items-center gap-2">
                  <EmptyStateDescription className="py-2 text-gray-200">
                    {`No linked account found for ${payoutState?.paymentMethod?.label.toLowerCase()} payment method`}
                  </EmptyStateDescription>
                  <Button
                    size="lg"
                    variant="default"
                    className="mx-20 mt-5"
                    onClick={() => {
                      completeStep(currentStep);
                      setAddRecipient(true);
                    }}
                  >
                    Add New Recipient
                  </Button>
                </div>
              </div>
            </EmptyStateContent>
            <EmptyStateConceal>
              <div className="flex flex-col gap-3">
                <SkeletonContent
                  isLoading={isLoading}
                  Component={CardLikeButtonSkeleton}
                >
                  <ScrollArea
                    className={"flex max-h-[380px] w-full flex-col pr-3"}
                  >
                    {filteredAccounts?.map((account: LinkedAccountType) => {
                      const labelId = `account-${account.id}`;
                      return (
                        <AccountCard
                          key={labelId}
                          account={account}
                          updateState={updateState}
                          setCurrentStep={setCurrentStep}
                          currentStep={currentStep}
                          completeStep={completeStep}
                        />
                      );
                    })}
                  </ScrollArea>
                  <Button
                    size="lg"
                    variant="default"
                    className="mx-20 mt-5"
                    onClick={() => {
                      setAddRecipient(true);
                    }}
                  >
                    <LoadingButtonContent Icon={<PlusIcon size="0.9rem" />}>
                      Add Recipient
                    </LoadingButtonContent>
                  </Button>
                </SkeletonContent>
              </div>
            </EmptyStateConceal>
          </EmptyStateRoot>
        </div>
      )}
    </>
  );
};

export default React.memo(StepRecipient);

export function FlatImage(props: ComponentProps<"img">) {
  return (
    <img
      {...props}
      src={props?.src}
      className={cn("aspect-[16/12] w-[32px]", props.className)}
      alt={props?.alt ?? "Flag image"}
    />
  );
}

export function AccountCard({
  account,
  updateState,
  completeStep,
  setCurrentStep,
  currentStep,
}: {
  account: LinkedAccountType;
  updateState?: (name: string, newState: unknown) => void;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
  completeStep?: (value: number) => void;
  currentStep?: number;
}) {
  const { payoutState } = useContext(PayoutCtx);
  const currency = getFiatCurrency(account?.currency);

  const optionIcon = (option: string) => {
    switch (option) {
      case "Bank":
      case "Manual Bank":
        return <BankFundingIcon color="#CCD1D6" w="35" h="34" />;
      case "Card":
        return <CardIcon color="#CCD1D6" w="35" h="34" />;
      default:
        return <BankFundingIcon color="#CCD1D6" w="35" h="34" />;
    }
  };

  return (
    <ClickableCard
      className={"mt-5 flex w-full items-center justify-between px-4 py-4"}
      onClick={() => {
        updateState("recipient", account);
        setCurrentStep(currentStep + 1);
        completeStep(currentStep);
      }}
    >
      <div className="flex items-center">
        <div className="mr-5 flex">
          {optionIcon(payoutState?.paymentMethod.label)}
          <div className="-ml-5 flex items-end">
            <FlatImage
              alt={currency.name}
              src={currency.flagUrl}
              className="aspect-[15/10] w-[20px] object-contain"
            />
          </div>
        </div>
        <div className="flex flex-col text-left">
          <span className="text-[18px] font-semibold text-gray-600">
            {account?.account?.label}
          </span>
          <div className="text-sm text-neutral-400">
            <span className="capitalize">{account?.optionType} - </span>
            <span>{`****${account?.account?.lastFourDigits}`}</span>
          </div>
        </div>
      </div>

      <div className="text-[32px] font-semibold text-[#002C3D]">
        <ChevronRight size={"1.2rem"} className="text-gray-400" />
      </div>
    </ClickableCard>
  );
}
