import React from "react";
import { Badge } from "~/components/atoms/Badge";

export function CountBadge({
  count = 0,
  size = "sm",
}: Pick<React.ComponentProps<typeof Badge>, "size"> & {
  count: number;
}) {
  return (
    <Badge
      type={"default"}
      showIcon={false}
      size={size}
      className={"!font-[400]"}
    >
      {count}
    </Badge>
  );
}

const status_color_map = {
  successful: { bg: "#EDFDF0", text: "#3BB75E" },
  success: { bg: "#EDFDF0", text: "#3BB75E" },
  active: { bg: "#EDFDF0", text: "#3BB75E" },
  pending: { bg: "#FFF9EB", text: "#FFAB00" },
  "in-progress": { bg: "#FFF9EB", text: "#FFAB00" },
  failed: { bg: "#FFEBEB", text: "red" },
  denied: { bg: "#FFEBEB", text: "red" },
  awaiting_confirmation: { bg: "#FFF9EB", text: "#FFAB00" },
};

export function StatusBadge({
  label: label_,
  leftIcon,
  rightIcon,
}: {
  label: string;
  leftIcon?: React.JSX.Element;
  rightIcon?: React.JSX.Element;
}) {
  const label = safeStr(label_);
  const match = status_color_map[label.toLowerCase()] ?? {};

  return (
    <div
      style={{ backgroundColor: match.bg }}
      className={
        "inline-flex h-[30px] items-center gap-1 rounded-2xl px-3 py-[10px]"
      }
    >
      {leftIcon && leftIcon}
      <span
        style={{ color: match.text }}
        className="mx-[5px] text-center text-[10px] font-bold capitalize"
      >
        {label.toUpperCase()}
      </span>
      {rightIcon && rightIcon}
    </div>
  );
}
export function NumberBadge({
  label: label_,
  count,
  leftIcon,
  rightIcon,
}: {
  label: string;
  count: number;
  leftIcon?: React.JSX.Element;
  rightIcon?: React.JSX.Element;
}) {
  const label = safeStr(label_);
  const match = status_color_map[label.toLowerCase()] ?? {};

  return (
    <div
      style={{ backgroundColor: match.bg }}
      className={
        "inline-flex h-[30px] items-center gap-1 rounded-2xl px-[8px] py-[10px]"
      }
    >
      {leftIcon && leftIcon}
      <span
        style={{ color: match.text }}
        className="mx-[5px] text-center text-xs font-bold capitalize"
      >
        {count}
      </span>
      {rightIcon && rightIcon}
    </div>
  );
}

export function StatusDot({ label }: { label: string }) {
  const backgroundColor = (
    status_color_map[label.toLowerCase()] ?? { text: "gray" }
  ).text;

  console.log("label", label);

  return <div style={{ backgroundColor }} className={"h-2 w-2 rounded-full"} />;
}
