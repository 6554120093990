import {
  Link,
  createFileRoute,
  useSearch,
  useRouter,
  useNavigate,
} from "@tanstack/react-router";
import { createActorContext } from "@xstate/react";
import { motion } from "framer-motion";
import {
  ArrowRight,
  LucideAlertOctagon,
  LucideCheckCircle,
} from "lucide-react";
import { useModal, useModalHandle } from "~/hooks/use-modal";
import { empty_state_no_cards } from "~/assets";
import React, { Dispatch, SetStateAction } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "~/@/components/ui/button";
import { Skeleton } from "~/@/components/ui/skeleton";
import { useRetrieveVirtualCollectionAccount } from "~/api/codegen/liquidityComponents";
import {
  BankFundingIcon,
  CardsIcon,
  CopyIcon,
  CreditCardIcon,
  CryptoFundingIcon,
  LinkIcon,
} from "~/assets/icons";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogRemote,
  DialogRemoteTrigger,
  DialogTitle,
} from "~/@/components/ui/dialog";
import {
  EmptyStateConceal,
  EmptyStateContent,
  EmptyStateRoot,
} from "~/@/components/custom/empty-state";
import {
  EmptyStateDescription,
  EmptyStateOverlay,
} from "~/components/molecules/empty-state";
import { notify } from "~/components/Toast";
import { Alert } from "~/components/atoms/Alert";
import { Badge } from "~/components/atoms/Badge";
import { SkeletonContent, SkeletonText } from "~/components/atoms/skeleton";
import {
  CardBackButton,
  CardBody,
  CardHeader,
  CardRoot,
  CardSeparator,
  CardTitle,
} from "~/components/layouts/Card";
import { CollectionAccount } from "~/general/interfaces";
import { useGetBusinessId } from "~/hooks/use-business";
import { WalletHolderImpl, useWallet, useWallets } from "~/hooks/use-wallet";
import { formatNumber, getFiatCurrency } from "~/libs/currency.helpers";
import { Wallet, AppToken } from "~/libs/factories/wallet-factory";
import { makePortal } from "~/libs/make-portal";
import { addFundsMachine } from "~/libs/state_machines/add_funds";
import { useCreateApplicationTokenAccount } from "~/api/codegen/liquidityComponents";
import { ClickableCard } from "~/components/ClickableCard";

const Caption = makePortal("add-funds-caption");
const AddFundsContext = createActorContext(addFundsMachine);

export const Route = createFileRoute("/dashboard/wallet/collections/fund")({
  component: Page,
});

const slide_in_out = {
  initial: {},
  hide: { x: [0, "-200%", "200%"], opacity: [1, 0, 0] },
  show: { x: 0, opacity: 1 },
};

function Page() {
  return (
    <AddFundsContext.Provider>
      <section className="flex justify-center py-12">
        <Content />
      </section>
    </AddFundsContext.Provider>
  );
}

function AutoSelect() {
  const { wallet_id } = useSearch({ strict: false });
  const actor = AddFundsContext.useActorRef();
  const { isLoading, data: wallet } = useWallet(wallet_id);

  React.useEffect(() => {
    if (isLoading) return;
    if (wallet.kind === "NONE") return;
    actor.send({
      type: "WALLET_CHOSEN",
      data: { currency: getFiatCurrency(wallet.currency), walletId: wallet.id },
    });
  }, [wallet, isLoading, actor]);

  return null;
}

function Content() {
  const state = AddFundsContext.useSelector((e) => {
    return e.value;
  });
  const actor = AddFundsContext.useActorRef();
  const router = useRouter();

  return (
    <CardRoot size="lg">
      <AutoSelect />
      <CardHeader className="flex justify-between gap-2">
        {state === "choose_wallet" && (
          <CardBackButton
            onClick={() => {
              router.history.back();
            }}
          />
        )}
        <motion.span
          variants={slide_in_out}
          animate={
            state !== "choose_wallet" && state !== "FUNDING_SUCCESS"
              ? "show"
              : "hide"
          }
          transition={{ duration: 0.2 }}
        >
          <CardBackButton
            onClick={() => {
              actor.send({ type: "BACK" });
            }}
          />
        </motion.span>

        <Caption.Outlet>
          <CardTitle />
        </Caption.Outlet>
      </CardHeader>

      <CardSeparator />

      {state === "choose_wallet" && <ChooseWallet />}

      {state === "choose_funding_method" && <ChooseFundingMethod />}

      {state === "view_transfer_details" && (
        <BalanceDetails setDisplay={() => {}} />
      )}

      {state === "FUNDING_SUCCESS" && (
        <CardBody className={"py-24"}>
          <div className={"flex flex-col items-center gap-2"}>
            <LucideCheckCircle className={"text-green-600"} />

            <h2 className={"text-2xl font-semibold"}>Money added</h2>

            <p
              className={"max-w-[45ch] text-balance text-center text-gray-600"}
            >
              Deposit confirmed. Funds should appear in your wallet shortly.
              Expect an email confirmation
            </p>

            <Link to={"/dashboard/wallet/spend"}>
              <Button
                size={"lg"}
                className={"mt-8"}
                onClick={() => {
                  actor.send({ type: "RESET" });
                }}
              >
                Continue
              </Button>
            </Link>
          </div>
        </CardBody>
      )}
    </CardRoot>
  );
}

function CardLikeButtonSkeleton() {
  return (
    <ClickableCard className="justify-between" disabled>
      <div className="flex basis-1/2 flex-col space-y-2">
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-12" />
          <Skeleton className="h-4 basis-7/12 rounded" />
        </div>
        <Skeleton className="h-3 w-full rounded" />
      </div>

      <Skeleton className="h-7 w-24 basis-4/12 rounded" />
    </ClickableCard>
  );
}

function ChooseWallet() {
  const { data, isLoading } = useWallets();
  const actor = AddFundsContext.useActorRef();
  const [selected, setSelected] = useState<Wallet>();

  const wallets = Array.from(
    WalletHolderImpl.toList(data, (wallet) => wallet.kind === "CORPORATE"),
  );

  const currency = getFiatCurrency(selected?.currency);

  return (
    <>
      <Caption.Slot>Select Wallet</Caption.Slot>

      <CardBody className="pt-5">
        <EmptyStateRoot isEmpty={wallets.length === 0 && !isLoading}>
          <EmptyStateContent>
            <div className="flex flex-col items-center">
              <img
                src={empty_state_no_cards}
                alt="Nope!"
                className="aspect-[3/2] max-w-[30ch] object-contain"
              />

              <div className="flex flex-col items-center gap-2">
                <EmptyStateDescription className="py-2 text-gray-200">
                  You have no wallet
                </EmptyStateDescription>

                <Link to="/dashboard/wallet/spend/setup">
                  <Badge type={"info"} size="sm" showIcon={false}>
                    Setup up a cash wallet
                  </Badge>
                </Link>
              </div>
            </div>
          </EmptyStateContent>
          <EmptyStateConceal>
            <div className="-mx-1 mt-[20px] flex flex-col gap-3">
              <SkeletonContent
                isLoading={isLoading}
                Component={CardLikeButtonSkeleton}
              >
                {wallets.map((item) => {
                  const currency = getFiatCurrency(item.currency);
                  return (
                    <ClickableCard
                      key={currency.code}
                      className={`items-center justify-between ${
                        selected?.currency === item?.currency &&
                        "border-[#0094FF]"
                      } px-4 py-4 `}
                      // disabled={item.currency !== "NGN"}
                      onClick={() => {
                        setSelected(item);
                      }}
                    >
                      <div>
                        <div className="flex gap-2">
                          <img
                            alt={currency.name}
                            src={currency.flagUrl}
                            className="aspect-[20/13.3] w-[20px] object-contain"
                          />
                          <span className="text-[20px] font-semibold text-gray-600">
                            {currency.code}
                          </span>
                        </div>
                        <div className="text-neutral-500">{currency.name}</div>
                      </div>

                      <div className="text-[32px] font-semibold text-[#002C3D]">
                        {formatNumber(item.balance, { decimal: true })}
                      </div>
                    </ClickableCard>
                  );
                })}

                <DialogRemoteTrigger
                  modalId={"add_web_hook"}
                  params={{
                    mode: "create",
                  }}
                  className=""
                  disabled={!currency}
                >
                  <Button
                    size="lg"
                    variant="default"
                    className="mx-32 mt-5"
                    disabled={!currency}
                    // onClick={() => {
                    //   actor.send({ type: "WALLET_CHOSEN", data: currency });
                    // }}
                  >
                    Continue
                  </Button>
                </DialogRemoteTrigger>
              </SkeletonContent>
            </div>
          </EmptyStateConceal>
        </EmptyStateRoot>
      </CardBody>
      <CheckoutWidget selected={selected} />
    </>
  );
}

function ChooseFundingMethod() {
  const actor = AddFundsContext.useActorRef();
  const currency = AddFundsContext.useSelector(
    React.useCallback((i) => i.context.currency, []),
  );
  const walletId = AddFundsContext.useSelector(
    React.useCallback((i) => i.context.walletId, []),
  );

  return (
    <>
      <Caption.Slot>Add {currency.name}</Caption.Slot>

      <CardBody className="pt-5">
        <span className="text-base leading-tight text-zinc-700">
          Select how to pay
        </span>

        <div className="-mx-1 mt-[20px] flex flex-col gap-3">
          <OptionCard
            bestRate={true}
            Icon={<BankFundingIcon />}
            heading="Wire/Bank Transfer"
            onClick={() =>
              actor.send({ type: "BANK_TRANSFER", data: { walletId } })
            }
          >
            Pay {currency.name} from a {currency.code} Bank Account
          </OptionCard>

          <OptionCard
            Icon={<CardsIcon />}
            heading="subWallet Transfer"
            disabled
          >
            Fund via wallet to wallet transfers. Swap fees apply on currency
            exchanges
          </OptionCard>

          <OptionCard Icon={<LinkIcon />} heading="Pay by Bank" disabled>
            Connect to your bank account and authorize a transfer
          </OptionCard>

          <OptionCard
            Icon={<CreditCardIcon />}
            heading="Pay with Card"
            disabled
          >
            Fund your wallet with a bank credit or debit card
          </OptionCard>

          <OptionCard
            Icon={<CryptoFundingIcon />}
            heading="Crypto Transfer Card"
            disabled
          >
            Connect and pay from a crypto wallet
          </OptionCard>
        </div>
      </CardBody>
    </>
  );
}

function BalanceDetails({
  setDisplay,
}: {
  setDisplay: Dispatch<SetStateAction<string>>;
}) {
  const actor = AddFundsContext.useActorRef();
  const walletId = AddFundsContext.useSelector(
    React.useCallback((i) => i.context.walletId, []),
  );
  const businessId = useGetBusinessId();
  const { data, isLoading, status } = useRetrieveVirtualCollectionAccount(
    {
      pathParams: {
        businessId,
        walletId,
      },
    },
    {
      staleTime: Infinity,
    },
  );

  const collection: CollectionAccount = safeObj(data?.data?.collectionAccount);

  const details: { title: string; value: string }[] = [
    { title: "Account Name", value: collection.accountName },
    { title: "Bank Name", value: collection.bankName },
    { title: "Account Number", value: collection.accountNumber },
  ];

  return (
    <>
      <Caption.Slot>Virtual Account Details</Caption.Slot>
      <CardBody className="flex flex-col gap-6 pt-5">
        {pipe(
          Match.value(status),
          Match.when("error", () => {
            return (
              <div className="flex flex-col items-center gap-2 py-12">
                <LucideAlertOctagon className="text-red-600" />

                <p className="text-center text-sm text-gray-600">
                  Error fetching collection account details
                </p>
              </div>
            );
          }),
          Match.orElse(() => {
            return (
              <>
                <Alert type={"info"}>
                  Ensure to copy the{" "}
                  <span className="font-semibold">Payment reference/code</span>{" "}
                  when you pay{" "}
                </Alert>

                <ul className="flex flex-col gap-4 pb-8">
                  {details.map((item) => {
                    return (
                      <li
                        key={item.title}
                        className="flex items-end justify-between"
                      >
                        <div className="flex flex-1 flex-col">
                          <div className="text-[14px] text-[#64748B]">
                            {item.title}
                          </div>
                          <div className="min-h-[24px] font-semibold text-[#64748B]">
                            <SkeletonText
                              isLoading={isLoading}
                              className="w-[10ch]"
                            >
                              {item.value}
                            </SkeletonText>
                          </div>
                        </div>

                        <CopyToClipboard
                          text={item.value}
                          onCopy={() => {
                            notify("success", `${item.title} copied`);
                          }}
                        >
                          <Button
                            size="icon"
                            variant="ghost"
                            className="rounded-full"
                          >
                            <CopyIcon />
                          </Button>
                        </CopyToClipboard>
                      </li>
                    );
                  })}
                </ul>

                <Button
                  size="lg"
                  className="-mb-8 w-full"
                  disabled={status !== "success"}
                  onClick={() => {
                    actor.send({ type: "PAYMENT_MADE" });
                  }}
                >
                  I made a payment
                </Button>
              </>
            );
          }),
        )}
      </CardBody>
    </>
  );
}

function OptionCard(
  props: React.ComponentProps<"button"> & {
    Icon: React.JSX.Element;
    heading: string;
    bestRate?: boolean;
  },
) {
  const { heading, Icon, children, bestRate = false, ...PROPS } = props;

  return (
    <ClickableCard {...PROPS}>
      <div className="flex flex-1 flex-col items-start gap-2 text-left">
        <div className="flex items-center gap-4">
          {Icon}
          <span className="text-2xl font-normal leading-tight text-stone-600">
            {heading}
          </span>
        </div>

        <p className="text-sm font-normal leading-tight text-gray-600">
          {children}
        </p>
      </div>

      <span className={"text-[#828282]"}>
        <ArrowRight strokeWidth={0.5} size="24" />
      </span>

      {bestRate ? (
        <span className="absolute right-2 top-2">
          <Badge showIcon={false} size="sm" type="info">
            Best rate
          </Badge>
        </span>
      ) : null}
    </ClickableCard>
  );
}

function CheckoutWidget({ selected }: { selected: Wallet }) {
  const modal = useModalHandle("add_web_hook");
  const iframeRef = useRef(null);
  const { refetch } = useWallets();
  const businessId = useGetBusinessId();
  const navigate = useNavigate();
  const [tokenDetails, setTokenDetails] = useState<AppToken>();

  const mutation = useCreateApplicationTokenAccount();

  const createApplicationToken = async () => {
    try {
      await mutation
        .mutateAsync({
          pathParams: { businessId },
          body: {
            tokenType: "CHECKOUT_WIDGET",
            currency: selected?.currency ?? "USD",
          },
        })
        .then((resp) => {
          // console.log("resp", resp?.data);
          setTokenDetails(resp?.data);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selected) {
      createApplicationToken();
    }
  }, [selected]);

  useEffect(() => {
    const handleMessage = async (event) => {
      if (event.data === "close-iframe") {
        await refetch();
        navigate({
          to: "/dashboard/wallet/spend",
        });
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <DialogRemote
      id="add_web_hook"
      callback={async () => {
        await refetch();
        navigate({
          to: "/dashboard/wallet/spend",
        });
      }}
    >
      <DialogContent className={"h-full max-w-full bg-[#F2F6F8] p-0 font-body"}>
        <DialogHeader className="hidden">
          <DialogTitle />
          <DialogDescription />
        </DialogHeader>
        {tokenDetails?.url && (
          <iframe
            ref={iframeRef}
            title="Checkout Widget"
            src={tokenDetails?.url}
            width="100%"
            height="100%"
            // frameBorder="0"
          />
        )}
      </DialogContent>
    </DialogRemote>
  );
}
