import React from "react";
import { DestinationCtx } from ".";
import { Button } from "~/@/components/ui/button";
import { Input } from "~/@/components/ui/input";
import * as Address from "~/components/Inputs/CountryAddressFields";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/@/components//ui/form";
import { CardSeparator } from "~/components/layouts/Card";
import { LoadingButtonContent } from "~/components/Buttons/LoadingButtonContent";
import { da } from "date-fns/locale";
import { notify } from "~/components/Toast";
import {
  // useAddLinkedAccountBankNgn,
  useGetLinkedAccounts,
} from "~/api/codegen/liquidityComponents";
import { useGetBusinessId } from "~/hooks/use-business";
import { use } from "i18next";
import { LinkedAccountType } from "../StepDestination";
import { WithdrawCtx } from "..";

const schema = z.object({
  selectedCountry: z.string(),
  cardName: z.string(),
  cardNumber: z.string(),
  exprieDate: z.string(),
  zipCode: z.string(),
});
const CardDetails = ({
  currentMove,
  setCurrentMove,
  setCurrentStep,
  currentStep,
}) => {
  const { updateState } = useContext(WithdrawCtx);
  const { resetState, destinationState } = useContext(DestinationCtx);
  const businessId = useGetBusinessId();
  // const mutation = useAddLinkedAccountBankNgn();
  const [accountName, setAccountName] = useState<string>("");

  const { data, isLoading, refetch } = useGetLinkedAccounts(
    {
      pathParams: {
        businessId,
        currency: destinationState?.country?.currency,
      },
    },
    {
      staleTime: Infinity,
      enabled: !!businessId,
    },
  );

  const form = useForm<z.infer<typeof schema>>();

  useEffect(() => {
    form.setValue("selectedCountry", destinationState?.country?.iso2);
  }, [destinationState?.country]);

  const linkedAccounts = useMemo(() => {
    return data?.data ?? [];
  }, [data]);

  const getAccount = () => {
    const newAccount = linkedAccounts?.filter(
      (account: LinkedAccountType) => account?.account?.label === accountName,
    );
    updateState("destination", newAccount[0]);
    setCurrentStep(currentStep + 1);
    resetState();

    return newAccount[0];
  };

  useEffect(() => {
    if (accountName && isLoading === false) {
      getAccount();
    }
  }, [accountName, isLoading]);

  function splitCardNumber(cardNumber: string) {
    if (!cardNumber) return "";
    if (cardNumber.length === 16) return "";

    const numericValue = cardNumber.replace(/[^0-9]/g, "");
    return numericValue.replace(/(\d{4})(?=\d)/g, "$1 ").trim();
  }

  function formatExpireDate(expireDate: string) {
    if (!expireDate) return "";
    const numericValue = expireDate.replace(/[^0-9]/g, "");
    return numericValue.replace(/(\d{2})(?=\d)/g, "$1 / ").trim();
  }

  return (
    <div className="px-10">
      <div className="mb-5 px-2 text-left">
        <h2 className="py-5 text-xl font-normal text-muted-foreground">
          Enter Card Details
        </h2>
      </div>
      <form
        className="mt-[20px] flex flex-col text-left"
        onSubmit={form.handleSubmit(async (data) => {
          const cardNum = data.cardNumber.replace(/\s/g, "");
          const [month, year] = data.exprieDate.split(" / ");

          if (cardNum?.length !== 16) {
            notify("error", "Invalid card number");
            return;
          }

          if (
            (Number(`20${year}`) < new Date().getFullYear() &&
              parseInt(month) < new Date().getMonth() + 1) ||
            (Number(`20${year}`) === new Date().getFullYear() &&
              parseInt(month) < new Date().getMonth() + 1) ||
            (Number(`20${year}`) === new Date().getFullYear() &&
              parseInt(month) === new Date().getMonth() + 1) ||
            parseInt(month) > 12
          ) {
            notify("error", "Invalid month");
            return;
          }

          if (Number(`20${year}`) < new Date().getFullYear()) {
            notify("error", "Invalid year");
            return;
          }

          try {
            // console.log("data", data);
            const cardDetails = {
              label: data.cardName,
              data: {
                name: data.cardName,
                card_number: data.cardNumber.replace(/\s/g, ""),
                expiration_date: {
                  month: month,
                  year: `20${year}`,
                },
              },
            };

            // await mutation.mutateAsync({
            //   pathParams: {
            //     token: destinationState?.tokendetails?.application?.token,
            //     currency:
            //       destinationState?.tokendetails?.application?.metadata
            //         ?.currency,
            //     option: "card",
            //     step: 1,
            //   },
            //   body: cardDetails,
            // });
            await refetch();
            setAccountName(data.cardName);
          } catch (err) {
            console.error(err);
            notify("error", `${err.message}`);
          }
        })}
      >
        <Form {...form}>
          <div className="">
            <Address.Root defaultValue={form.watch("selectedCountry")}>
              <FormField
                control={form.control}
                name={"selectedCountry"}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Country</FormLabel>
                      <FormControl>
                        <Address.CountryField
                          {...field}
                          value={`${destinationState?.country?.iso2}`}
                          disabled={true}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </Address.Root>
            <div>
              <h2 className="pt-10 text-base font-normal text-gray-400">
                Card Details
              </h2>
              <CardSeparator className="my-2 mb-5" />
              <div className="flex w-full items-center gap-8">
                <div className="w-full">
                  <FormField
                    control={form.control}
                    name={"cardNumber"}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Card Number</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              value={splitCardNumber(field.value)}
                              placeholder="0000 0000 0000 0000"
                              className="w-full"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </div>
                <div className="w-full">
                  <FormField
                    control={form.control}
                    name={"cardName"}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Card Holder Name</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder=""
                              className="w-full"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="mt-5 flex w-full items-center gap-8">
                <div className="w-full">
                  <FormField
                    control={form.control}
                    name={"exprieDate"}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Expiration Date</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              value={formatExpireDate(field.value)}
                              placeholder="MM / YY"
                              className="w-full"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </div>
                <div className="w-full">
                  <FormField
                    control={form.control}
                    name={"zipCode"}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Zip Code</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder=""
                              className="w-full"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 flex items-center justify-between px-2">
            <Button
              size="lg"
              variant="outline"
              className="mt-5 w-[15%]"
              onClick={() => {
                setCurrentMove(currentMove - 1);
              }}
            >
              Back
            </Button>
            <Button
              size="lg"
              type="submit"
              variant="default"
              className="mt-5 w-[15%]"
              // disabled={!form.formState.isDirty}
            >
              <LoadingButtonContent loading={false}>Next</LoadingButtonContent>
            </Button>
          </div>
        </Form>
      </form>
    </div>
  );
};

export default React.memo(CardDetails);
