"use client";
import * as React from "react";
import { createFileRoute } from "@tanstack/react-router";

import { Card, CardContent } from "~/@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { Input } from "~/@/components/ui/input";
import { Button } from "~/@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "~/@/components/ui/dialog";
import { OTPInput } from "~/components/Inputs";

interface AmountInputProps {
  walletBalance?: number;
  minAmount?: number;
  onBack?: () => void;
  onNext?: (amount: number) => void;
}

export const Route = createFileRoute("/dashboard/wallet/collections/amount")({
  component: Page,
});

export default function Page() {
  const [open, setOpen] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  return (
    <section className="flex justify-center py-12">
      <AmountInput
        walletBalance={4000.0}
        minAmount={10.0}
        onBack={() => {}}
        onNext={() => {
          setOpen(true);
        }}
      />
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Enter OTP</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Enter the OTP sent to your email
          </DialogDescription>
          <OTPInput value={otp} setValue={setOtp} />
        </DialogContent>
      </Dialog>
    </section>
  );
}

function AmountInput({
  walletBalance = 2000.0,
  minAmount = 10.0,
  onBack,
  onNext,
}: AmountInputProps) {
  const [amount, setAmount] = React.useState<string>("");

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Only allow numbers and decimal points
    const value = e.target.value.replace(/[^0-9.]/g, "");
    // Prevent multiple decimal points
    const parts = value.split(".");
    if (parts.length > 2) return;
    if (parts[1]?.length > 2) return;
    setAmount(value);
  };

  const isValidAmount = React.useMemo(() => {
    const numAmount = parseFloat(amount || "0");
    return numAmount >= minAmount && numAmount <= walletBalance;
  }, [amount, minAmount, walletBalance]);

  return (
    <Card className="mx-auto w-full max-w-md">
      <CardContent className="pt-6">
        <div className="mb-6 flex items-center justify-between">
          <h2 className="text-2xl font-medium">Amount</h2>
          <Select defaultValue="cash">
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select payment" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="cash">Cash</SelectItem>
              <SelectItem value="card">Card</SelectItem>
              <SelectItem value="crypto">Crypto</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <p className="mb-4 text-base text-muted-foreground">
          You&apos;re moving
        </p>

        <div className="mb-4 flex gap-4">
          <div className="flex w-[120px] items-center gap-2 rounded-md border p-3">
            <span className="font-medium">USD</span>
          </div>
          <Input
            type="text"
            value={amount}
            onChange={handleAmountChange}
            className="text-right text-2xl"
            placeholder="0.00"
          />
        </div>

        <div className="mb-6 flex justify-between text-sm">
          <div>
            Wallet Balance{" "}
            <span className="font-medium text-blue-500">
              {walletBalance.toFixed(2)}
            </span>{" "}
            USD
          </div>
          <div>
            Minimum Amount{" "}
            <span className="font-medium text-blue-500">
              {minAmount.toFixed(2)}
            </span>{" "}
            USD
          </div>
        </div>

        <div className="flex justify-between">
          <Button variant="outline" onClick={onBack}>
            Back
          </Button>
          <Button
            onClick={() => onNext?.(parseFloat(amount))}
            disabled={!isValidAmount}
            className="bg-blue-500 hover:bg-blue-600"
          >
            Next
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
